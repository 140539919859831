export const styles = {
    container: {
        boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 20px 4px",
        borderRadius: '20px',
        minWidth: '500px',
        width:'auto',
        height: 'auto',
        padding: '20px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pageButton: {
        border: '1px solid black',
        padding: '5px 10px',
        cursor: 'pointer',
        background: 'white',
    },
    activeButton: {
        background: 'lightgray',
    },
    title: {
        textAlign: 'center',
        padding: '10px 0',
        borderBottom: '2px solid black',
    },
    table: {
        margin: '20px',
        border: '2px solid black',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
};
import React, { useContext } from "react";
import { Route, Redirect } from 'react-router-dom'
import { Decrypt } from "../Util/Crypto";

const RoutesPrivate = ({ component: Component, ...rest }) => {
    let user = window.localStorage.getItem("user");
    if(!user)
    {
       window.location.href = "/"
    }else
    {
        user = JSON.parse(Decrypt(user));
    }


    return (
        <Route
            {...rest}
            render={() => user ? (
                <Component {...rest} />
            )
                :
                <Redirect to='/' />
            }
        >
        </Route>
    )
}
export default RoutesPrivate;
import React, { Component } from 'react';
import './login.css'
import { Encrypt } from "../../Util/Crypto";
import { api } from '../../components/Api/api';
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { openAlert } from '../../Util/AlertUtil';
import ModalRecoverPSW from '../../components/Modals/RecoverPsw';

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            psw: '',
            showPsw: false
        }
    }
    render() {
        const { email, psw } = this.state;

        const SendForm = () => {
            if (email !== "" && psw !== '') {
                api.get(`Usuario/Login?Email=${email}&&Senha=${psw}`, response => {
                    window.localStorage.clear();
                    var str = Encrypt(JSON.stringify(response.data.usuario));
                    window.localStorage.setItem("user", str);
                    window.localStorage.setItem("empresa", response.data.nomeEmpresa);
                    window.localStorage.setItem("screens", response.data.token.split('.')[1]);
                    window.localStorage.setItem("access_token", response.data.token);
                    window.location.href = "/home"
                }, erro => {
                    if (erro.response.status === 400) {
                        openAlert(erro.response.data, false)
                    } else {
                        openAlert(erro, false)
                    }
                });
            }
        }

        const OnChangeText = (e) => {
            this.setState({ [e.target.name]: e.target.value })
        }

        const resetFields = () => {
            document.getElementsByName("email")[0].value = "";
            document.getElementsByName("psw")[0].value = "";
            this.setState({ email: "", psw: "" });
        }
        const openRecoverModal = () => {
            resetFields();
        }

        return (
            <div className="backgroud-login">
                <div className="login-page">
                    <div className="left-container-login">
                        <div style={{ display: 'flex', gap: 10 }}>
                            <BsFillTelephoneFill size={30} color='var(--color-secundaria)' />
                            <h3>Hub Telefonia</h3>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div style={{ width: '100%' }}>
                            <form className="login-form" onSubmit={e => { e.preventDefault(); SendForm() }}>
                                <div className="input-email">
                                    <input className='form-control' onChange={OnChangeText} type="email" value={this.state.email} name="email" placeholder="exemplo@email.com.br" required />
                                </div>
                                <br />
                                <div className="input-group mb-3">
                                    <input className='form-control' onChange={OnChangeText} style={{ width: '100%' }} type={this.state.showPsw ? "text" : "password"} id="password" placeholder='Senha' name="psw" value={this.state.psw} required />
                                    <div className="input-group-prepend" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                        <button style={{ background: 'none', border: 'none', position: 'absolute', right: '.5rem', zIndex: 1100 }} onClick={() => this.setState({ showPsw: !this.state.showPsw })} type='button' className='login-eye'>{this.state.showPsw ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}</button>
                                    </div>
                                </div>
                                <br />
                                <p style={{ display: 'flex', justifyContent: "start", alignItems: "center" }}>
                                    <a onClick={openRecoverModal}><ModalRecoverPSW /></a>
                                </p>

                                <button style={{ width: '100%', color: '#fff', background: 'var(--color-secundaria)' }} className='btn btn-danger' type="submit">ENTRAR</button>
                            </form>
                        </div>
                    </div>
                    <div className="right-container-login">
                        <div>
                            <h1>Bem-vindo!</h1><br />
                            <h1>Juntos, somos mais fortes</h1>
                        </div>
                    </div>
                </div>
            </div>
            // <LayoutLogin>
            //     <FormLogin
            //         Email={email}
            //         Psw={psw}
            //         OnChangeText={OnChangeText}
            //         SendForm={SendForm}
            //     />
            // </LayoutLogin>
        );
    }
}

export default Login;
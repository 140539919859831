import React, { useCallback, useEffect, useState } from 'react';
import './addLayoutBase.css';
import Layout from '../../components/Layout/layout';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';

const Enum = {
    IGNORAR: 0,
    DATAATUALIZACAO: 1,
    CPF: 2,
    DDD: 3,
    TELEFONE: 4,
    OPERADORA: 5,
    SCORE: 6,
    FACEBOOK: 7,
    WHATSAPP: 8,
    ORIGEM: 9
};

const AddNewLayout = () => {
    const [longestWidth, setLongestWidth] = useState(0);
    const [layout, setLayout] = useState([]);
    const [mappedLayout, setMappedLayout] = useState([]);
    const [name, setName] = useState('');
    const [selectedEnumValues, setSelectedEnumValues] = useState([]);
    const [selectedLayout, setSelectedLayout] = useState([]);

    useEffect(() => {
        // Função para atualizar o mappedLayout com os itens selecionados
        const updateMappedLayout = () => {
            const newMappedLayout = selectedEnumValues.map((value, index) => ({
                Id: 0,
                Nome: getKeyByValue(Enum, value),
                Posicao: index,
                Descricao: layout[index],
                EmpresaId: 0,
                Tipo_Layout: 0
            }));
            setMappedLayout(newMappedLayout);
        };

        // Mede a largura máxima dos itens do Enum
        const measureWidth = () => {
            let maxWidth = 0;
            Object.entries(Enum).filter(([key]) => key !== "IGNORAR").forEach(([_, value]) => {
                const optionWidth = document.getElementById(`option-${value}`)?.offsetWidth;
                if (optionWidth > maxWidth) {
                    maxWidth = optionWidth;
                }
            });
            setLongestWidth(maxWidth);
        };

        measureWidth();
        updateMappedLayout(); // Atualiza o mappedLayout quando o componente for montado

        return () => {
            // Remove event listeners ou outras tarefas de limpeza, se necessário
        };
    }, [Enum, layout, selectedEnumValues]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            if (!fileName.endsWith('.csv')) {
                openAlert('Selecione um arquivo CSV.', false, true);
                return;
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                var input = document.querySelector('input[type="file"]')
                var data = new FormData()
                for (const file of input.files) {
                    data.append('file', file, file.name)
                }
                api.post("File/CarregarCabecalho", data, res => {
                    const initialLayout = res.data.split(';');
                    setLayout(initialLayout);

                    const selectedValues = initialLayout.map(column => {
                        return getEnumValue(column) || Enum.IGNORAR;
                    });
                    setSelectedEnumValues(selectedValues);

                    input.value = '';
                }, err => {
                    input.value = '';
                    openAlert("Houve um erro ao importar o arquivo", false);
                })
            };
            reader.readAsText(file);
        }
    };

    const reorderLayout = (initialLayout) => {
        return initialLayout;
    };

    const getEnumValue = (columnName) => {
        const lowerCaseColumnName = columnName.toLowerCase();
        for (const key in Enum) {
            if (Enum.hasOwnProperty(key)) {
                if (key.toLowerCase() === lowerCaseColumnName) {
                    return Enum[key];
                }
            }
        }
        return null;
    };

    const changeDisabled = (disabled, id) => {
        var options = document.querySelectorAll("#value-" + id);
        options.forEach(element => {
            element.disabled = disabled;
        });
    }

    const MapColumnLayout = (index, event) => {
        const value = parseInt(event.target.value);
        setSelectedEnumValues(prevState => {
            const updatedValues = [...prevState];
            updatedValues[index] = value;
            return updatedValues;
        });
    };

    const formIsValid = () => {
        console.log("mappedLayout");
        console.log(mappedLayout);
        if (!mappedLayout.find(x => x.Nome.toUpperCase() === "TELEFONE")) {
            console.log(mappedLayout);
            alert("Telefone é um campo obrigatório.");
            return false;
        }
        if (name.trim() === '') {
            alert("Apelido para o Layout é um campo obrigatório.");
            return false;
        }
        return true;
    }


    const sendForm = () => {
        if (formIsValid()) {
            const filteredLayoutToSend = mappedLayout.filter(item => item.Nome !== "IGNORAR");
            console.log("filteredLayoutToSend:", filteredLayoutToSend);

            if (filteredLayoutToSend.length === 0) {
                alert("Nenhum campo foi selecionado além de 'IGNORAR'. Selecione pelo menos um campo.");
                return;
            }

            const layoutToSend = {
                "Campos": filteredLayoutToSend,
                "Descricao": name
            };

            console.log("layoutToSend:", layoutToSend);

            console.log("layoutToSend:", layoutToSend);
            api.post("File/SalvarLayout", layoutToSend, res => {
                window.location.href = '/importbase'
            }, erro => {
                openAlert("Houve um erro ao salvar o layout: Tipo de Layout já definido", false);
            })
        }
    }

    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    };


    return (
        <>
            <button className='btn btn-primary' style={{ marginLeft: 30 }} onClick={() => window.history.back()}>Voltar</button>
            <br />
            <br />
            <div className="container">
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <span style={{ fontSize: 30 }}>1°- Importe seu arquivo <b>(Layout tem que ser em.CSV)</b></span>
                        <input onChange={handleFileChange} type="file" className='form-control' name="file" id="file" />
                    </div>
                </div>
            </div>
            <br />
            {layout.length > 0 && <div className="container"><span>2°- Realiza o mapeamento do Layout importado <b>(seu x nosso)</b></span></div>}
            {layout.length > 0 && <div className="container"><small>obs:</small></div>}
            {layout.length > 0 && <div className="container"><small>Telefone é um campo obrigatório.</small></div>}
            {layout.length > 0 && <div className="container"><small>Caso DDD estaja junto com o Telefone, selecione apenas o campo "Telefone".</small></div>}
            <br />
            {layout.length > 0 && <div className='container' style={{ overflow: 'auto' }}>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            {layout.map((item, index) => (
                                <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {layout.map((item, index) => (
                                <td key={index} style={{ width: 'auto' }} >
                                    <select
                                        value={selectedEnumValues[index]}
                                        onChange={(e) => MapColumnLayout(index, e)}  // Adicionamos um novo manipulador de evento para lidar com a alteração de seleção
                                        style={{ width: 'auto' }}
                                        className="form-select"
                                    >
                                        <option style={{ width: 'auto' }} value="0">IGNORAR</option>
                                        {Object.keys(Enum).filter(key => key !== "IGNORAR").map(key => (
                                            <option
                                                key={Enum[key]}
                                                value={Enum[key]}
                                            >
                                                {key.replace(/([A-Z])/g, ' $1').trim()}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>}
            <br />
            {layout.length > 0 && <div className="container">
                <span>3°- Dê um apelido para o <b>Layout Mapeado</b></span>
                <br />
                <div className="row">
                    <br />
                    <div className="col-md-12">
                        <input
                            value={name}
                            onChange={e => setName(e.target.value.toUpperCase())}
                            style={{ outline: '1px solid dodgerblue', width: '30%', padding: 10 }}
                            type="text"
                            placeholder='Ex: Layout Telefonia'
                            name='name'
                            id='name'
                            className='form-control'
                        />
                    </div>
                </div>
            </div>}
            <br />
            {layout.length > 0 && <div className="container">
                <span>4°- Envia o mapeamento do <b>Layout Mapeado</b></span>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <button type='button' onClick={() => sendForm()} className='btn btn-primary'>Enviar</button>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default AddNewLayout;

import React, { useState } from "react";
import { Component } from "react";
import { Pergunta } from '../../Util/AlertUtil';

class Pagination extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            firstIndex: 1,
            lastIndex: this.props.countPerPage,
            numbers: [],
            countPages: 0
        }

        this.Reload = (history, filter = false) => {
            var l = this.state.currentPage * this.props.countPerPage;
            var f = l - this.props.countPerPage;
            const npage = Math.ceil(history.length / this.props.countPerPage);
            const numbers = [...Array(npage + 1).keys()].slice(1);

            this.setState({ lastIndex: l, firstIndex: f, countPages: npage, numbers: numbers })

            const records = history.slice(f, l);
            records.length > 0 && this.props.setRecords(records);

            if (filter)
                this.setState({ currentPage: 1 });
        }

        this.ToggleCountPages = (num, currentData) => {
            debugger
            var l = this.state.currentPage * num;
            var f = l - num;
            const npage = Math.ceil(currentData.length / num);
            const numbers = [...Array(npage + 1).keys()].slice(1);

            this.setState({ lastIndex: l, firstIndex: f, countPages: npage, numbers: numbers })

            const records = currentData.slice(f, l);
            records.length > 0 && this.props.setRecords(records);

            this.setState({ currentPage: 1 });
        }
    }

    componentDidMount() {
        this.togglePage(1);
    }

    togglePage = (page) => {
        var l = page * this.props.countPerPage;
        var f = l - this.props.countPerPage;

        const npage = Math.ceil(this.props.data.length / this.props.countPerPage);
        const numbers = [...Array(npage + 1).keys()].slice(1);

        this.setState({ lastIndex: l, firstIndex: f, countPages: npage, numbers: numbers })

        const records = this.props.data.slice(f, l);
        records.length > 0 && this.props.setRecords(records);
    }

    prevPage = () => {
        if (this.state.currentPage !== 1) {
            this.setState({ currentPage: this.state.currentPage - 1 })
            this.togglePage(this.state.currentPage - 1);
        }
    }

    nextPage = () => {
        if (this.state.currentPage !== this.countPages && this.state.currentPage < this.state.numbers.length) {
            this.setState({ currentPage: this.state.currentPage + 1 })
            this.togglePage(this.state.currentPage + 1);
        }
    }

    customPage = async (page, isNext) => {
        let buscar = false;

        if (isNext && (this.state.currentPage + page) > this.state.numbers.length) {
            if (await Pergunta("Numero da página digitada maior que quantidade de paginas\nDeseja buscar pelo numero maximo?")) {
                this.setState({ currentPage: this.state.numbers.length })
                this.togglePage(this.state.numbers.length);
            } else {
                return;
            }
        } else if (!isNext && (this.state.currentPage - page) <= 0) {
            if (await Pergunta("Numero da página digitada menor que 1\nDeseja buscar pelo numero minimo?")) {
                this.setState({ currentPage: 1 })
                this.togglePage(1);
            } else {
                return;
            }
        } else {
            if (isNext) {
                this.setState({ currentPage: this.state.currentPage + page })
                this.togglePage(this.state.currentPage + page);
            } else {
                this.setState({ currentPage: this.state.currentPage - page })
                this.togglePage(this.state.currentPage - page);
            }
        }
    }

    indexPage = (i) => {
        this.setState({ currentPage: i })
        this.togglePage(i);
    }

    render() {
        return (
            <nav aria-label="Navegação de página exemplo" style={{ marginTop: 10 }}>
                <ul class="pagination">
                    <li style={{ cursor: 'pointer' }} class="page-item"><a onClick={() => this.customPage(10, false)} class="page-link" >-10</a></li>
                    <li style={{ cursor: 'pointer' }} class="page-item"><a onClick={() => this.customPage(5, false)} class="page-link" >-5</a></li>
                    <li style={{ cursor: 'pointer' }} class="page-item"><a onClick={this.prevPage} class="page-link" >Anterior</a></li>
                    <div style={{ paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{this.state.currentPage} de {this.state.numbers.length > 0 ? this.state.numbers.length : 1}</div>
                    <li style={{ cursor: 'pointer' }} class="page-item"><a onClick={this.nextPage} class="page-link" >Próximo</a></li>
                    <li style={{ cursor: 'pointer' }} class="page-item"><a onClick={() => this.customPage(5, true)} class="page-link" >+5</a></li>
                    <li style={{ cursor: 'pointer' }} class="page-item"><a onClick={() => this.customPage(10, true)} class="page-link" >+10</a></li>
                </ul>
            </nav>
        )
    }

}
export default Pagination;
import React, { useState, useRef, useEffect } from 'react';
import OcorrenciaTable from './ocorrenciaTable';
import Pagination from '../../components/Pagination/pagination';
import Modal from './ModalOcorrencia';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import { Col, Row } from 'react-bootstrap';

function Ocorrencia() {
    const [Ocorrencias, setOcorrencias] = useState([]);
    const [records, setRecords] = useState([]);
    const [qtdCategory, setQtdCategory] = useState(10)
    const [scores, setScores] = useState([]);
    const [orderby, setOrderby] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef();
    const pagination = useRef();

    const BuscarOcorrencias = (first = false) => {
        api.get(`BuscarTodasOcorrencias?primeiroAcesso=${first}`, res => {
            setOcorrencias(res.data);
            pagination.current.Reload(res.data);
            if (first && res.data.filter(x => x.ocorrencia_pontos === -1).length > 1)
                alert("Novas Ocorrencias foram identificadas, por favor, ajustar a pontuação.")
            if (first)
                BuscarScores();
        }, erro => {
            openAlert("Houve um erro na solicitação: " + erro.data,false)
        })
    }

    const BuscarScores = () => {
        api.get("BuscarTodosScore", res => {
            setScores(res.data);
        }, erro => {
            openAlert("Houve um erro na solicitação: " + erro.data,false)
        })
    }

    useEffect(async () => {
        BuscarOcorrencias(true);
    }, []);

    const onChangeFilter = (e) => {
        const value = e.target.value;
        let filter = [];
        if (value === '') {
            filter = Ocorrencias;
        } else {
            filter = Ocorrencias.filter(Ocorrencia => Ocorrencia.ocorrencia_nome.toUpperCase().includes(value.toUpperCase()));
        }
        pagination.current.Reload(filter, true);
    }

    const onDeleteOcorrencia = (id) => {
        if (window.confirm("Deseja realmente deletar esse Ocorrencia?")) {
            api.delete("ExcluirOcorrencia?id=" + id, res => {
                BuscarOcorrencias();
                openAlert("Ocorrencia excluido com sucesso!");
            }, erro => {
                openAlert("Houve um erro na solicitação: " + erro.data,false)
            })
        }
    }

    const OrderBy = (num) => {
        num = parseInt(num);
        // <option value={5}>Pontos (Crescente)</option>
        // <option value={6}>Pontos (Decrecente)</option>
        let filter = [];
        if (num === 1 || num === 2) {
            filter = num === 1 ? Ocorrencias.sort((a, b) => {
                const nomeA = a.ocorrencia_nome.toUpperCase();
                const nomeB = b.ocorrencia_nome.toUpperCase();
                if (nomeA < nomeB) {
                    return -1;
                }
                if (nomeA > nomeB) {
                    return 1;
                }
                return 0;
            }) :
                Ocorrencias.sort((a, b) => {
                    const nomeA = a.ocorrencia_nome.toUpperCase();
                    const nomeB = b.ocorrencia_nome.toUpperCase();
                    if (nomeB < nomeA) {
                        return -1;
                    }
                    if (nomeB > nomeA) {
                        return 1;
                    }
                    return 0;
                });
        } else if (num === 3 || num === 4) {
            filter = num === 3 ? Ocorrencias.sort((a, b) => {
                return a.ocorrencia_id - b.ocorrencia_id;
            }) :
                Ocorrencias.sort((a, b) => {
                    return b.ocorrencia_id - a.ocorrencia_id;
                });
        } else if (num === 5 || num === 6) {
            filter = num === 5 ? Ocorrencias.sort((a, b) => {
                return a.ocorrencia_pontos - b.ocorrencia_pontos;
            }) : Ocorrencias.sort((a, b) => {
                return b.ocorrencia_pontos - a.ocorrencia_pontos;
            });
        } else {
            filter = Ocorrencias;
        }
        pagination.current.Reload(filter, true);
        setOrderby(num);
    }

    const OpenModalOcorrencia = (index) => {
        var ocorrenciaAtual = Ocorrencias.filter(x => x.ocorrencia_id == index)[0];
        modalRef.current.ShowOcorrencia(ocorrenciaAtual);
        setShowModal(true);
    }

    return (
        <div className='container'>
            <Modal
                ref={modalRef}
                BuscarOcorrencias={BuscarOcorrencias}
                Edit={true}
                showModal={showModal}
                setShowModal={setShowModal}
                Scores={scores}
            />
            <center>
                <h1>Ocorrência</h1>
            </center>
            <div style={{ padding: 20, border: '3px dotted dodgerblue', borderRadius: 10 }}>
                <h5>FILTROS:</h5>
                <div className="row" >
                    <div className="col-md-2" />
                    <div className="col-md-8">
                        <label>Pesquisar:</label>
                        <input style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} onChange={onChangeFilter} type="text" className='form-control' placeholder='Nome Do Ocorrencia' />
                    </div>
                    {/* <div className="col-md-1">
                    <Modal
                        BuscarOcorrencias={BuscarOcorrencias}
                        show={showModalAdd}
                        setShow={setShowModalAdd}
                        Edit={false}
                        Ocorrencia={null}
                    />
                </div> */}
                </div>
                <br />
                <Row>
                    <div className="col-md-2" />
                    <Col md={4}>
                        <label>Quantidade de registros por pagina:</label>
                        <select style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} onChange={e => { pagination.current.ToggleCountPages(e.target.value, Ocorrencias); setQtdCategory(e.target.value) }} value={qtdCategory} className='form-control'>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={Ocorrencias.length}>MAX</option>
                        </select>
                    </Col>
                    <Col md={4}>
                        <label>Ordenar Por:</label>
                        <select style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} onChange={e => OrderBy(e.target.value)} value={orderby} className='form-control'>
                            <option value={0}>-- Selecione --</option>
                            <option value={1}>Ordem Alfabetica A-Z</option>
                            <option value={2}>Ordem Alfabetica Z-A</option>
                            <option value={3}>Id Ocorrencia (Crescente)</option>
                            <option value={4}>Id Ocorrencia (Decrecente)</option>
                            <option value={5}>Pontos (Crescente)</option>
                            <option value={6}>Pontos (Decrecente)</option>
                        </select>
                    </Col>
                </Row>
            </div>
            <div className='container' style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <br /><span>Total De Ocorrencias Registradas: {Ocorrencias.length}</span>
                <br />
                <OcorrenciaTable
                    Scores={scores}
                    ref={pagination}
                    Ocorrencias={Ocorrencias}
                    records={records}
                    setRecords={setRecords}
                    BuscarOcorrencias={BuscarOcorrencias}
                    onDeleteOcorrencia={onDeleteOcorrencia}
                    OpenModalOcorrencia={OpenModalOcorrencia}
                    setShowModal={setShowModal}
                />
                {Ocorrencias.length === 0 && <span>Nenhum Ocorrencia Cadastrado...</span>}
                <Pagination
                    ref={pagination}
                    data={Ocorrencias}
                    countPerPage={qtdCategory}
                    setRecords={setRecords}
                />
            </div>
        </div>
    );
}

export default Ocorrencia;
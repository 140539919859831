import React from 'react';
import './search.css'
import Pagination from '../../components/Pagination/pagination';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import { AiFillFile } from 'react-icons/ai';
import { createRef } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

const Search = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [result, setResult] = useState([]);
    const [phoneHistory, setPhoneHistory] = useState([]);
    const [cpf, setCpf] = useState('');
    const [path, setPath] = useState('');
    const [records, setRecords] = useState([]);
    const [isColumn, setIsColumn] = useState(false);

    const refPagination = useRef();

    const haddleSubmit = () => {
        const dataForm = new FormData();
        debugger;
        dataForm.append("Cpf", cpf);
        dataForm.append("TipoRetorno", document.getElementById("isColumn").checked);
        dataForm.append("ManterArquivo", true);

        const mapeamento = {
            Cpf: cpf,
            TipoRetorno: document.getElementById("isColumn").checked
        };

        api.post(`Cpf/BuscarCpf`, {
            Cpf: cpf,
            TipoRetorno: document.getElementById("isColumn").checked,
            ManterArquivo: true
        }, res => {
            const { path, listaDadosTelefone } = res.data;
            setPhoneHistory(listaDadosTelefone)
            setPath(path)
            setIsColumn(false)

            if (listaDadosTelefone?.length === 0) {
                openAlert('Para o cpf: ' + cpf + ' nenhum telefone foi encontrado!', false, true);
                return;
            }

            if (refPagination.current) {
                if (refPagination.current.Reload)
                    refPagination.current.Reload(listaDadosTelefone);
            }

        }, erro => {
            debugger;
            if (Array.isArray(erro?.response?.data)) {
                let erros = "";
                erro.response.data.forEach(element => {
                    erros += "\n" + element.descricao + "\n";
                });
                openAlert('Algo deu errado\n erro:' + erros, false)
            }
            else {
                openAlert('Algo deu errado\n erro: ' + erro.response.data, false)
            }
        });

    }

    const isNum = (value) => {
        value = value.replaceAll('.', '').replaceAll('-', '')

        var er = /[^0-9.]/;
        er.lastIndex = 0;
        if (er.test(value)) {
            return false;
        }
        return true;
    }

    const DownloadFile = () => {
        let csvContent = atob(path);
        var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url
        a.download = "retorno.csv";
        a.click();
    }

    const maskCpf = (e) => {
        if (isNum(e.target.value)) {
            setCpf(e.target.value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"))
        }
    }

    return (
        <section>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: 'column' }}>
                <br />
                <div>
                    <h1 style={{ textAlign: 'center' }}>Filtra por <span style={{ textDecoration: 'underline dodgerblue', padding: '10px', color: 'dodgerblue', fontWeight: 'bold' }}>CPF</span></h1>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <span>Resultado em:</span>
                    <label style={{ marginLeft: 10, marginRight: 5 }}>Linha</label>
                    <input
                        type='radio'
                        checked={!isColumn}
                        name='isColumn'
                        onChange={() => setIsColumn(false)}
                    />
                    <label style={{ marginLeft: 10, marginRight: 5 }}>Coluna</label>
                    <input
                        type='radio'
                        id="isColumn"
                        name='isColumn'
                        checked={isColumn}
                        onChange={() => setIsColumn(true)}
                    />
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '30%' }}>
                    <input style={{ outline: '1px solid #008552', padding: '10px 20px' }} type='text' id='cpf' value={cpf} onChange={maskCpf} placeholder='Ex: 999.999.999-99' />
                    <button className='btn-download'
                        style={{
                            padding: '12.5px',
                            borderRadius: '0px 10px 10px 0px'
                        }}
                        onClick={haddleSubmit}
                    >Buscar</button>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", flexDirection: 'column' }}>
                {phoneHistory && phoneHistory.length > 0 &&
                    <div style={{ position: 'absolute', right: '1rem', bottom: '6rem' }}>
                        <button type='button' onClick={() => DownloadFile()} style={{ padding: 10, marginRight: 50 }} className='btn-download'><AiFillFile />Download Arquivo</button>
                    </div>}

                {phoneHistory &&
                    phoneHistory.length > 0 &&
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Ordem</th>
                                <th>CPF</th>
                                <th>DDD</th>
                                <th>Telefone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                phoneHistory.length > 0 &&
                                records.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{++index}</td>
                                            <td>{item.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                            <td>{item.ddd}</td>
                                            <td>{item.telefone}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                }
                <Pagination
                    ref={refPagination}
                    data={phoneHistory}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
        </section>
    );
}

export default Search;
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import { FaPlus } from 'react-icons/fa';

function NewScore({ BuscarScores, Edit = false, Icon, score }) {
    const [name, setName] = useState('');
    const [points, setPoints] = useState(0);
    const [id, setId] = useState(0);
    const [show, setShow] = useState(false);

    const onSubmit = () => {
        api.post(Edit ? "EditarScore" : "CadastraScore", { Id: id, NomeScore: name, Valor: points }, res => {
            openAlert(Edit ? "Score Atualizado com sucesso!" : "Score Cadastrado com sucesso!")
            BuscarScores();
            setName('');
            setPoints(0);
            setShow(false);
        }, erro => {
            openAlert("Houve um erro na solicitação: " + erro.data, false)
        })
    }

    useEffect(() => {
        if (Edit && score) {
            setName(score.descricao);
            setPoints(score.pontos);
            setId(score.id);
        } else {
            setName('');
            setPoints('');
            setId(0);
        }
    }, [])

    const closeModal = () => {
        if (Edit && score) {
            setName(score.descricao);
            setPoints(score.pontos);
            setId(score.id);
        }
        setShow(false);
    }

    return (
        <>
            <button onClick={() => setShow(true)} style={!Edit ? { position: 'absolute', right: '2rem' } : {}} className={Edit ? 'btn btn-warning' : 'btn btn-primary'}>{Edit && Icon} {!Edit && 'Adicionar Novo'} {!Edit && <FaPlus size={20} />}</button>
            <Modal show={show}>
                <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                    <Modal.Header>
                        <h1>{Edit ? 'Editar' : 'Adicionar Novo'} Score</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={8}>
                                <label>Nome:</label>
                                <input required value={name} onChange={e => setName(e.target.value)} type="text" placeholder='Nome' style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} />
                            </Col>
                            <Col md={4}>
                                <label>Pontos:</label>
                                <input required value={points} onChange={e => setPoints(e.target.value)} type="number" min={0} style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <button type='button' style={{ width: '30%', padding: '15px' }} onClick={closeModal} className='btn btn-danger'>Voltar</button>
                        <button style={{ width: '30%' }} type='submit' className='btn btn-primary'>{Edit ? "Salvar" : "Cadastrar"}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default NewScore;

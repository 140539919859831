import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './Routes/routes';
import './index.css'
import { AiFillAlert, AiOutlineCloseCircle } from 'react-icons/ai';
import { Loading } from './components/Loader/loading';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <div id='alert' className='alert-telefonia'>
      <div className='alert_icon'>
        <AiFillAlert size={25} color="red" />
        <AiOutlineCloseCircle style={{ cursor: 'pointer' }} onClick={() => {
          document.getElementById('alert').classList.remove('show_alert');
        }} size={25} color="red" />
      </div>
      <br />
      <span id='text_alert' className='text'>
      </span>
    </div>
    <Loading />
    <Routes />
  </>
);

import React, { Component, createRef } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class EditOperador extends Component {

    constructor() {
        super()
        this.state = {
            show: false,
            idOperador: 0,
            operadorName: '',
            email: '',
            cpf: '',
            phone: '',
        }
        this.ToEdit = ({
            idOperador,
            operadorName,
            email,
            cpf,
            phone,
        }) =>
            this.setState({
                idOperador,
                operadorName,
                email,
                cpf: cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
                phone: phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
                show: true
            })
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    render() {
        const {
            idOperador,
            operadorName,
            email,
            cpf,
            phone,
        } = this.state;

        const maskPhone = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ phone: e.target.value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") })
            }
        }

        const maskCpf = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ cpf: e.target.value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") })
            }
        }

        const isNum = (value) => {
            value = value.replaceAll('.', '').replaceAll('-', '')
                .replaceAll('/', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
            var er = /[^0-9.]/;
            er.lastIndex = 0;
            if (er.test(value)) {
                return false;
            }
            return true;
        }

        const { Edit } = this.props;

        return (<>

            <Modal show={this.state.show}>
                <form onSubmit={e => {
                    e.preventDefault(); Edit({
                        idOperador,
                        operadorName,
                        email,
                        cpf,
                        phone,
                    }); this.setState({ show: false })
                }}>
                    <Modal.Header>
                        <h3>Editar Operador</h3>
                        <Button onClick={() => { this.handleModal() }}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 10 }}>
                                <label>Nome do Operador</label>
                                <input value={operadorName} onChange={e => this.setState({ operadorName: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome do operador*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <label>Email do operador</label>
                                <input value={email} onChange={e => this.setState({ email: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email do operador*' type="email" className='form-control' required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <label>CPF do Operador</label>
                                <input value={cpf} onChange={maskCpf} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='CPF*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>DDD+Telefone</label>
                                <input value={phone} onChange={maskPhone} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='DDD* + Telefone*' type="text" className='form-control' required />
                            </div>
                        </div>
                        <button className="btn btn-success" type="submit">Editar</button>
                    </Modal.Body>
                </form>
            </Modal>
        </>)
    }
}
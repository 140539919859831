import React, { Component, createRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../Util/AlertUtil'
import { api } from '../Api/api'


export default class ModalRecoverPSW extends Component {

    constructor() {
        super()
        this.state = {
            show: false,
            email: ''
        }
    }

    handleRecover() {
        const data = new FormData();
        data.append("email", this.state.email)

        api.post("Email/RecuperarSenha", data, res => {
            openAlert('Foi enviado um e-mail \n Por favor confira sua caixa de entrada ou spam!')
        }, err => {
            openAlert(err.response.data, false)
        })
        this.handleModal();
    }

    handleModal() {
        this.setState({ show: !this.state.show });
        if (this.state.show) {
            this.setState({ email: '' });
        }
    }

    render() {
        return (<>

            <a id="modalrecoversw" onClick={() => { this.handleModal() }} className='a' >Esqueci a minha senha</a>


            <Modal show={this.state.show}>
                <form onSubmit={e => { e.preventDefault(); this.handleRecover() }}>
                    <Modal.Header>
                        Recuperar Senha
                    </Modal.Header>

                    <Modal.Body>
                        <input required onChange={e => this.setState({ email: e.target.value })} value={this.state.email} type="email" className='form-group form-group-lg' placeholder='Digite seu e-mail' />
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn btn-primary" type="submit">Enviar</button>
                        <Button onClick={() => { this.handleModal() }}>Fechar</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>)
    }
}
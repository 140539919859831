import React from 'react';
import NewScore from './newScore';
import { FaPencilAlt, FaTrash } from "react-icons/fa";

export default ({
    BuscarScores,
    showModal,
    setShowModal,
    records,
    onDeleteScore
}) => {
    return (
        <table className='table table-striped'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Pontos</th>
                    <th>Editar</th>
                    <th>Excluir</th>
                </tr>
            </thead>
            {records.map((score, i) => (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{score.descricao}</td>
                    <td>{score.pontos}</td>
                    <td>
                        <NewScore
                            score={score}
                            BuscarScores={BuscarScores}
                            show={showModal}
                            setShow={setShowModal}
                            Icon={<FaPencilAlt size={20} />}
                            Edit={true}
                        />
                    </td>
                    <td><button onClick={() => onDeleteScore(score.id)} className='btn btn-danger'><FaTrash size={20} /></button></td>
                </tr>
            ))}
        </table>

    )
}
import React, { Component, createRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../Util/AlertUtil'
import { api } from '../Api/api'


export default class EditCompany extends Component {

    constructor() {
        super()
        this.state = {
            show: false,
            companyName: '',
            email: '',
            cnpj: '',
            phone: '',
            contactEmail: '',
            name: '',
            emailNamePhone: '',
            idEmpresa: 0
        }
        this.ToEdit = ({ companyName, email, cnpj, phone, contactEmail, name, emailNamePhone, idEmpresa }) =>
            this.setState({ companyName, email, cnpj: cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"), phone: phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"), contactEmail, name, emailNamePhone, idEmpresa, show: true })
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    render() {
        const {
            idEmpresa,
            companyName,
            email,
            cnpj,
            phone,
            contactEmail,
            name,
            emailNamePhone,
        } = this.state;

        const maskPhone = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ phone: e.target.value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") })
            }
        }

        const maskCnpj = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ cnpj: e.target.value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") })
            }
        }

        const isNum = (value) => {
            value = value.replaceAll('.', '').replaceAll('-', '')
                .replaceAll('/', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
            var er = /[^0-9.]/;
            er.lastIndex = 0;
            if (er.test(value)) {
                return false;
            }
            return true;
        }

        const { Edit } = this.props;

        return (<>

            <Modal show={this.state.show}>
                <form onSubmit={e => {
                    e.preventDefault(); Edit({
                        idEmpresa,
                        companyName,
                        email,
                        cnpj,
                        phone,
                        contactEmail,
                        name,
                        emailNamePhone,
                    });this.setState({show:false})
                }}>
                    <Modal.Header>
                        <h3>Editar Empresa</h3>
                        <Button onClick={() => { this.handleModal() }}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 10 }}>
                                <label>Nome da empresa</label>
                                <input value={companyName} onChange={e => this.setState({ companyName: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome da empresa*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <label>Email da empresa</label>
                                <input value={email} onChange={e => this.setState({ email: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email da empresa*' type="email" className='form-control' required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Cnpj da empresa</label>
                                <input value={cnpj} onChange={maskCnpj} minLength={18} maxLength={18} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='CNPJ*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>DDD+Telefone</label>
                                <input value={phone} onChange={maskPhone} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='DDD* + Telefone*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Email de contato</label>
                                <input value={contactEmail} onChange={e => this.setState({ contactEmail: e.target.value })} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email de contato*' type="email" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Nome</label>
                                <input value={name} onChange={e => this.setState({ name: e.target.value })} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Email de cobrança associado</label>
                                <input value={emailNamePhone} onChange={e => this.setState({ emailNamePhone: e.target.value })} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email de cobrança associado ao nome e telefone*' type="email" className='form-control' required />
                            </div>
                        </div>
                        <button className="btn btn-success" type="submit">Editar</button>
                    </Modal.Body>
                </form>
            </Modal>
        </>)
    }
}
import React, { useEffect, useState } from 'react';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import { FaPencil, FaTrash } from "react-icons/fa6";
import EditLayout from '../../components/Modals/EditLayout';
import AddLayout from '../../components/Modals/AddLayout'
import CorrectLayout from '../../components/Modals/CorrectLayout';
import { useLocation } from 'react-router-dom';

function ToManagerLayout() {
    const [layouts, setLayouts] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    const paramValue = query.get('param') === 'true';

    const getMyLayouts = () => {
        api.get("File/BuscarTodosLayouts?layoutBase=" + paramValue, res => {
            if (res.data.length == 0) {
                window.location.href = '/addlayout';
            }
            setLayouts(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    useEffect(() => {
        getMyLayouts();
    }, [paramValue])

    const deleteLayout = (id) => {
        if (window.confirm("Deseja realmente excluir essa Layout?")) {
            api.delete(`File/DeletarLayouts?id=${id}`, res => {
                getMyLayouts()
            }, erro => {
                openAlert('Algo deu errado\n erro:' + erro, false)
            })
        }
    }

    const handleTempTask = () => {
        console.log("Temp task triggered");
    };

    return (
        <section className='container'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 20 }}>
                <button className='btn btn-primary' style={{ marginLeft: 30 }} onClick={() => window.history.back()}>Voltar</button>
                <div style={{ marginLeft: '20px' }}>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th style={{ width: '60%' }}>Apelido</th>
                                {!paramValue && <th>Tipo Origem</th>}
                                <th>Mudar Apelido</th>
                                <th>Alterar Layout</th>
                                <th>Deletar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {layouts.map(item => {
                                return <tr>
                                    <td>{item.id}</td>
                                    <td>{item.tipo}</td>
                                    {!paramValue && <td>{item.tipo_Origem === null ? "-" : item.tipo_Origem}</td>}
                                    <td><EditLayout name={item.tipo} id={item.id} key={item.id} tipo_Origem={item.tipo_Origem} /></td>
                                    <td><CorrectLayout text={<FaPencil color='#fff' />} temp={[]} classLink={false} tempTask={handleTempTask} id={item.id} /></td>
                                    <td><button onClick={() => deleteLayout(item.id)} className='btn btn-danger' type="button"><FaTrash color='#fff' /></button></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}

export default ToManagerLayout;
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import Pagination from '../../components/Pagination/pagination';
import EditCompany from '../../components/Modals/EditCompany';
import { Collapse } from 'react-bootstrap';

function RegisterCompany() {
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [phone, setPhone] = useState('');

    const [contactEmail, setContactEmail] = useState('');
    const [name, setName] = useState('');
    const [emailNamePhone, setEmailNamePhone] = useState('');

    const [openRegister, SetOpenRegister] = useState(false);

    const [records, setRecords] = useState([]);
    const refPagination = useRef();
    const refEdit = useRef();
    const [companys, setCompanys] = useState([]);
    const [oldCompanys, setOldCompanys] = useState([]);

    const [registered, setRegistered] = useState(false);
    const [registeredUser, setRegisteredUser] = useState({
        email: '', psw: ''
    });

    useEffect(() => {
        api.get("usuario/empresas", res => {
            setCompanys(res.data)
            setOldCompanys(res.data)
            refPagination.current.Reload(res.data);
        }, erro => {
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }, [])

    const GetCompanys = () => {
        api.get("usuario/empresas", res => {
            setCompanys(res.data)
            setOldCompanys(res.data)
            refPagination.current.Reload(res.data);
        }, erro => {
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    const isNum = (value) => {
        value = value.replaceAll('.', '').replaceAll('-', '')
            .replaceAll('/', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
        var er = /[^0-9.]/;
        er.lastIndex = 0;
        if (er.test(value)) {
            return false;
        }
        return true;
    }

    const maskPhone = (e) => {
        if (isNum(e.target.value)) {
            setPhone(e.target.value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"))
        }
    }

    const maskCnpj = (e) => {
        if (isNum(e.target.value)) {
            setCnpj(e.target.value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"))
        }
    }

    const inactivateCompany = (id) => {
        if (window.confirm("Deseja realmente inativar a empresa?")) {
            const data = new FormData()
            data.append("EmpresaId", parseInt(id))
            api.post("Usuario/InativarEmpresa", data, res => {
                GetCompanys();
                openAlert("Empresa inativada com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const activateCompany = (id) => {
        if (window.confirm("Deseja realmente ativar a empresa?")) {
            const data = new FormData()
            data.append("EmpresaId", parseInt(id))
            api.post("Usuario/AtivarEmpresa", data, res => {
                GetCompanys();
                openAlert("Empresa ativada com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const submit = () => {
        api.post("/Usuario/CadastroEmpresa", {
            NomeEmpresa: companyName,
            Email: email,
            CNPJ: cnpj,
            Telefone: phone,
            EmailContato: contactEmail,
            Nome: name,
            EmailAssociado: emailNamePhone
        }, res => {
            openAlert('Cadastro Realizado com sucesso!\nFoi enviado as credenciais de acesso no email: ' + email);
            GetCompanys();
        }, erro => {
            setRegistered(false)
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    const Edit = ({
        companyName,
        email,
        cnpj,
        phone,
        contactEmail,
        name,
        emailNamePhone,
        idEmpresa
    }) => {
        api.put("Usuario/AtualizarEmpresa", {
            IdEmpresa: idEmpresa,
            NomeEmpresa: companyName,
            EmailEmpresa: email,
            CNPJ: cnpj,
            DdddTelefone: phone,
            EmailContato: contactEmail,
            Nome: name,
            EmailCobranca: emailNamePhone
        }, res => {
            openAlert("Empresa atualizado com sucesso!")
            GetCompanys()
        }, erro => {
            if (erro.response.status === 400) {
                alert(erro.response.data)
            } else {
                alert(erro)
            }
        });
    }

    const filterNameCompany = (e) => {

        if (e.target.value == '') {
            refPagination.current.Reload(oldCompanys);
            return;
        }

        const result = companys.filter(x => x.nomeEmpresa.toUpperCase().includes(e.target.value.toUpperCase()));

        if (result.length === 0) {
            refPagination.current.Reload([]);
            setRecords([])
            return;
        }

        refPagination.current.Reload(result);

    }

    return (
        <>
            <EditCompany ref={refEdit} Edit={Edit} />
            <Collapse in={openRegister}>
                <form className='container' style={{ padding: '35px', width: '64%', boxShadow: 'rgba(0, 0, 0, 0.2) 1px 1px 20px 4px', borderRadius: '10px', marginTop: 15 }} onSubmit={e => { e.preventDefault(); submit() }}>
                    <center>
                        <h1>Cadastrar Nova Empresa</h1>
                    </center>
                    <br />
                    <small style={{ fontSize: '12px', marginLeft: '7px' }}>Campos com * são obrigatórios</small>
                    <div className="row">
                        <div className="col-md-6">
                            <br />
                            <input value={companyName} onChange={e => setCompanyName(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome da empresa*' type="text" className='form-control' required />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <input value={email} onChange={e => setEmail(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email da empresa*' type="email" className='form-control' required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <br />
                            <input value={cnpj} onChange={maskCnpj} minLength={18} maxLength={18} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='CNPJ*' type="text" className='form-control' required />
                        </div>
                        <div className="col-md-3">
                            <br />
                            <input value={phone} onChange={maskPhone} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='DDD* + Telefone*' type="text" className='form-control' required />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <input value={contactEmail} onChange={e => setContactEmail(e.target.value)} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email de contato*' type="email" className='form-control' required />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <input value={name} onChange={e => setName(e.target.value)} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome*' type="text" className='form-control' required />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <input value={emailNamePhone} onChange={e => setEmailNamePhone(e.target.value)} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email de cobrança associado ao nome e telefone*' type="email" className='form-control' required />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: "end" }}>
                            <button type="button" onClick={() => SetOpenRegister(false)} style={{ width: '100%', backgroundColor: '#f2f2f2', color: '#000', padding: 15, borderRadius: 10 }} className='btn'>FECHAR</button>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <button type="submit" className='btn btn-primary'>CADASTRAR</button>
                        </div>
                    </div>
                </form>
            </Collapse>
            {openRegister && <hr />}
            {!openRegister && <br />}
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '500px', flexDirection: 'column' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4" />
                        <div className="col-md-4">
                            <h3 style={{ textAlign: 'center' }}>Empresas cadastradas</h3>
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }}>
                            <button className='btn btn-success' onClick={() => SetOpenRegister(!openRegister)}>Adicionar +</button>
                        </div>
                    </div>
                </div>
                {companys.length === 0 && <span>Nenhuma empresa cadastrada...</span>}
                {companys.length > 0 && <br />}
                {companys.length > 0 && <div style={{ display: 'flex' }}>
                    <div />
                    <input onChange={filterNameCompany} style={{ width: '17rem', border: '1px solid dodgerblue', padding: 10 }} type="text" className='form-control' placeholder='Filtrar pelo nome da empresa' />
                </div>}
                {companys.length > 0 && <br />}
                <div style={{ display: 'flex', width: '100%' }}>
                    {companys.length > 0 && <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Nome da empresa</th>
                                <th>Email da empresa</th>
                                <th>CNPJ</th>
                                <th>DDD+TELEFONE</th>
                                <th>Nome</th>
                                <th>Email de contato</th>
                                <th>Email de cobrança</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.length === 0 && <td>Nenhuma empresa encontrada...</td>}
                            {companys.length > 0 &&
                                records.map(item => {

                                    const company = {
                                        companyName: item.nomeEmpresa,
                                        email: item.emailEmpresa,
                                        cnpj: item.cnpj,
                                        phone: item.dddtelefone,
                                        contactEmail: item.emailContato,
                                        name: item.nome,
                                        emailNamePhone: item.emailCobranca,
                                        idEmpresa: item.idEmpresa
                                    }

                                    return <tr>
                                        <td>{item.nomeEmpresa}</td>
                                        <td>{item.emailEmpresa}</td>
                                        <td>{item.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                        <td>{item.dddtelefone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.emailContato}</td>
                                        <td>{item.emailCobranca}</td>
                                        <td>{item.status ? "Ativo" : "Inativo"}</td>
                                        {item.status && <td><button className='btn btn-success' onClick={() => refEdit.current.ToEdit(company)} >Editar</button></td>}
                                        {item.status && <td><button className='btn btn-danger' onClick={() => inactivateCompany(item.idEmpresa)}>Inativar</button></td>}
                                        {!item.status && <td><button className='btn btn-warning' onClick={() => activateCompany(item.idEmpresa)}>Ativar</button></td>}
                                    </tr>

                                })}
                        </tbody>
                    </table>}
                </div>
                <Pagination
                    ref={refPagination}
                    data={companys}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
        </>
    );
}

export default RegisterCompany;
import React from 'react';

function MapDetails({ state, onChange, isEdit }) {
    return (
        <div>
            {Object.keys(state).map(key => {
                if (key != "cpf") {
                    if (key == "id") {
                        return (
                            <div key={key}>
                                <span>{`${key}`.toUpperCase()}</span>
                                <input
                                    style={{ padding: 10,border:'1px solid dodgerblue' }}
                                    value={state[key]}
                                    name={key}
                                    type="text"
                                    disabled
                                    className='form-control'
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div key={key}>
                                <span>{`${key}`.toUpperCase()}</span>
                                <input
                                    style={{ padding: 10,border:'1px solid dodgerblue' }}
                                    onChange={onChange}
                                    value={state[key]}
                                    name={key}
                                    type="text"
                                    disabled={!isEdit}
                                    className='form-control'
                                />
                            </div>
                        );
                    }
                }
            })}
        </div>
    );
}

export default MapDetails;

import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../Util/AlertUtil';
import React, { useState, useEffect, } from 'react';
import { api } from '../../components/Api/api';

export default ({ text, temp = [], classLink = false, tempTask, id }) => {

    const Enum = {
        IGNORAR: 0,
        DataDiscagem: 1,
        CPF: 2,
        Origem: 3,
        DDD: 4,
        Telefone: 5,
        Status: 6,
        Operadora: 7,
        Whatsapp: 8,
        Facebook: 9,
        Score: 10
    }

    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [layout, setLayout] = useState([]);
    const [mappedLayout, setMappedLayout] = useState([]);
    const [tipoArquivo, setTipoArquivo] = useState('');
    const [selectedEnumValues, setSelectedEnumValues] = useState([]);

    useEffect(() => {
        // Função para atualizar o mappedLayout com os itens selecionados
        const updateMappedLayout = () => {
            const newMappedLayout = selectedEnumValues.map((value, index) => ({
                Id: 0,
                Nome: getKeyByValue(Enum, value),
                Posicao: index,
                Descricao: layout[index],
                EmpresaId: 0,
                Tipo_Layout: 0
            }));
            setMappedLayout(newMappedLayout);
        };

        // Mede a largura máxima dos itens do Enum
        const measureWidth = () => {
            let maxWidth = 0;
            Object.entries(Enum).filter(([key]) => key !== "IGNORAR").forEach(([_, value]) => {
                const optionWidth = document.getElementById(`option-${value}`)?.offsetWidth;
                if (optionWidth > maxWidth) {
                    maxWidth = optionWidth;
                }
            });
        };

        measureWidth();
        updateMappedLayout(); // Atualiza o mappedLayout quando o componente for montado

    }, [Enum, layout, selectedEnumValues]);

    const handleTipoChange = (event) => {
        setTipoArquivo(event.target.value);
    };

    const getEnumValue = (columnName) => {
        const lowerCaseColumnName = columnName.toLowerCase();
        for (const key in Enum) {
            if (Enum.hasOwnProperty(key)) {
                if (key.toLowerCase().indexOf(lowerCaseColumnName.substring(1, 4)) !== -1) {
                    return Enum[key];
                }
            }
        }
        return null;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            if (!fileName.endsWith('.csv')) {
                openAlert('Selecione um arquivo CSV.', false, true);
                return;
            }

            const reader = new FileReader();
            reader.readAsText(file, 'ISO-8859-1');
            var input = document.querySelector('input[type="file"]')
            var data = new FormData()
            for (const file of input.files) {
                data.append('file', file, file.name)
            }
            api.post("File/CarregarCabecalho", data, res => {
                const initialLayout = res.data.split(';');
                setLayout(initialLayout);

                const selectedValues = initialLayout.map(column => {
                    return getEnumValue(column) || Enum.IGNORAR;
                });
                setSelectedEnumValues(selectedValues);

                input.value = '';
            }, err => {
                input.value = '';
                openAlert("Houve um erro ao importar o arquivo", false);
            })
        }
    };

    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    };

    const MapColumnLayout = (index, event) => {
        debugger;
        const value = parseInt(event.target.value);
        setSelectedEnumValues(prevState => {
            const updatedValues = [...prevState];
            updatedValues[index] = value;
            return updatedValues;
        });
    };

    const formIsValid = () => {
        if (mappedLayout.filter(x => x.Nome === getKeyByValue(Enum, Enum.Telefone)) === 0) {
            alert("Telefone � um campo obrigat�rio.")
            return false;
        }
        return true;
    }

    const sendForm = () => {
        if (formIsValid()) {
            const layout = {
                "Campos": mappedLayout,
                "Id": id
            }
            api.put("File/EditLayouts", layout, res => {
                window.location.href = '/history'
            }, erro => {
                openAlert("Houve um erro ao salvar o layout: Tipo de Layout já definido", false);
            })
        }
    }

    return (
        <>
            <button style={{ width: classLink ? '00%' : '80%', textAlign: 'center' }} className={classLink ? "btn btn-link" : "btn btn-warning"} onClick={() => setShow(true)}>{text}</button>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>Importe o arquivo para corrigir o layout.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container">
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <spam style={{ texta: 30 }}>1°- Importe seu arquivo <b>(Layout tem que ser em.CSV)</b></spam>
                                <input onChange={handleFileChange} type="file" className='form-control' name="file" id="file" />
                            </div>
                        </div>
                    </div>
                    <br />
                    {layout.length > 0 && <div className="container"><spam >2°- Realiza o mapeamento do Layout importado <b>(seu x nosso)</b></spam></div>}
                    {layout.length > 0 && <div className="container"><small >obs:</small></div>}
                    {layout.length > 0 && <div className="container"><small >Telefone é um campo obrigatório.</small></div>}
                    {layout.length > 0 && <div className="container"><small >Caso o DDD está junto ao Telefone, selecione apenas o campo "Telefone".</small></div>}
                    <br />
                    {layout.length > 0 && <div className='container' style={{ overflow: 'auto' }}>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    {layout.map(item => {
                                        return <th>{item}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {layout.map((item, index) => (
                                        <td key={index} style={{ width: 'auto' }} >
                                            <select
                                                value={selectedEnumValues[index]}
                                                onChange={(e) => MapColumnLayout(index, e)}  // Adicionamos um novo manipulador de evento para lidar com a altera��o de sele��o
                                                style={{ width: 'auto' }}
                                                className="form-select"
                                            >
                                                <option style={{ width: 'auto' }} value="0">IGNORAR</option>
                                                {Object.keys(Enum).filter(key => key !== "IGNORAR").map(key => (
                                                    <option
                                                        key={Enum[key]}
                                                        value={Enum[key]}
                                                    >
                                                        {key.replace(/([A-Z])/g, ' $1').trim()}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                    <br />
                    {layout.length > 0 && <div className="container">
                    </div>}
                    <br />
                    <br />
                    <br />
                    {layout.length > 0 && <div className="container">
                        <spam >5°- Envia o mapeamento do <b>Layout Mapeado</b></spam>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <button type='button' onClick={() => sendForm()} className='btn btn-primary'>Enviar</button>
                            </div>
                        </div>
                    </div>}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => setShow(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

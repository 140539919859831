import Swal from 'sweetalert2';

export const openAlert = (text, success = true, info = false) => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    Toast.fire({
        icon: info ? 'info' : success ? "success" : 'error',
        title: text
    });
}

export const Pergunta = (texto) => {
    return new Promise((resolve) => {
        Swal.fire({
            title: texto,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            resolve(result.isConfirmed);
        });
    });
};

export const closeAlert = (text) => {
    document.getElementById('text_alert').innerHTML = '';
    document.getElementById('alert').classList.remove('show_alert');
}
import React, { useEffect, useRef, useState } from 'react';
import { api } from '../../components/Api/api';
import EmpresasTable from './EmpresasTable';
import { FaPlus } from 'react-icons/fa';
import { Decrypt } from '../../Util/Crypto';
import Pagination from '../../components/Pagination/pagination';
import ModalEmpresa from '../../components/Modals/Usuario/ModalEmpresa';
import { openAlert } from '../../Util/AlertUtil';
import ModalOperador from '../../components/Modals/Usuario/ModalOperador';


function Usuarios() {
    const [data, setData] = useState([]);
    const [records, setRecords] = useState([]);
    const refPagination = useRef();
    const refModalEmpresa = useRef();
    const refModalOperador = useRef();

    let user = window.localStorage.getItem("user");
    if (!user) {
        window.location.href = "/"
    } else {
        user = JSON.parse(Decrypt(user));
        console.clear()
        console.log(user);
    }

    const BuscarUsuarios = () => {
        api.get('Usuario/BuscarUsuarios', res => {
            setData(res.data);
            setRecords([]);
            refPagination.current.Reload(res.data, true);
        }, err => console.log(err))
    }

    useEffect(() => BuscarUsuarios(), []);


    const inactivateCompany = (id) => {
        if (window.confirm("Deseja realmente inativar a empresa?")) {
            const data = new FormData()
            data.append("EmpresaId", parseInt(id))
            api.post("Usuario/InativarEmpresa", data, res => {
                BuscarUsuarios();
                openAlert("Empresa inativada com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const activateCompany = (id) => {
        if (window.confirm("Deseja realmente ativar a empresa?")) {
            const data = new FormData()
            data.append("EmpresaId", parseInt(id))
            api.post("Usuario/AtivarEmpresa", data, res => {
                BuscarUsuarios();
                openAlert("Empresa ativada com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const inactivateOperador = (id) => {
        if (window.confirm("Deseja realmente inativar esse operador?")) {
            const data = new FormData()
            data.append("OperadorId", parseInt(id))
            api.post("Usuario/InativarOperador", data, res => {
                BuscarUsuarios();
                openAlert("Operador inativado com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const activateOperador = (id) => {
        if (window.confirm("Deseja realmente ativar esse Operador?")) {
            const data = new FormData()
            data.append("OperadorId", parseInt(id))
            api.post("Usuario/AtivarOperador", data, res => {
                BuscarUsuarios();
                openAlert("Operador ativada com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    return (
        <div style={{ paddingLeft: '2rem' }}>
            <br />
            <ModalEmpresa ref={refModalEmpresa} AtualizarTela={BuscarUsuarios} />
            <ModalOperador ref={refModalOperador} AtualizarTela={BuscarUsuarios} />
            {user && user.usuario_tipo === 1 && <button className='btn btn-primary' type='button' onClick={e => refModalEmpresa.current.Novo()}><FaPlus /> Adicionar Nova Empresa</button>}
            {user && user.usuario_tipo === 1 && <br />}
            {user && user.usuario_tipo === 1 && <br />}
            <EmpresasTable activateOperador={activateOperador} inactivateOperador={inactivateOperador} refModalOperador={refModalOperador} refModalEmpresa={refModalEmpresa} data={records} activateCompany={activateCompany} inactivateCompany={inactivateCompany} usuarioId={user && user.usuario_id} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Pagination
                    ref={refPagination}
                    data={data}
                    countPerPage={4}
                    setRecords={setRecords}
                />
            </div>
        </div>
    );
}

export default Usuarios;
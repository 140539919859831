import React, { useEffect, useState } from 'react';
import { api } from '../../components/Api/api';
import './painel.css';
import { HiOutlineStatusOnline, HiOutlineStatusOffline } from "react-icons/hi";
import { styles } from './styles';
import axios from 'axios';

function Painel() {
    const [statusBatchImportaBase, setStatusBatchImportaBase] = useState(false);
    const [statusBatchHistoricoTelefonia, setStatusBatchHistoricoTelefonia] = useState(false);
    const [statusBatchScore, setStatusBatchScore] = useState(false);

    const [activePage, setActivePage] = useState(1);

    const [historyImportaBase, setHistoryImportaBase] = useState([]);
    const [historyHistoricoTelefonia, setHistoryHistoricoTelefonia] = useState([]);
    const [enriquecimento, setEnriqueimento] = useState([]);

    const GetStatusBatch = async () => {
        try {
            const token = window.localStorage.getItem("access_token");
            const response = await axios.get("Painel/GetStatusBatch", {
                baseURL: api.urlBase,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const { batchImportaBase, batchHistoricoTelefonia, batchScore } = response.data;
            setStatusBatchImportaBase(batchImportaBase);
            setStatusBatchHistoricoTelefonia(batchHistoricoTelefonia);
            setStatusBatchScore(batchScore);
        } catch (error) {
            console.log("Erro ao recuperar status batch:", error);
        }
    };

    const StartBatch = async (batch) => {
        try {
            const data = new FormData();
            data.append("ProcessName", batch);

            const token = window.localStorage.getItem("access_token");
            const response = await axios.post('Painel/RunBatchServidor', data, {
                baseURL: api.urlBase,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            GetStatusBatch();
        } catch (error) {
            console.log("Erro ao executar batch:", error);
        }
    };

    const GetHistoryImportaBase = async () => {
        try {
            const token = window.localStorage.getItem("access_token");
            const response = await axios.get("Painel/GetImportacaoBase", {
                baseURL: api.urlBase,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setHistoryImportaBase(response.data || []);
        } catch (error){
            console.log("Erro ao recuperar historico importação de base:", error)
        }
    };

    const GetHistoricoTelefonia = async () => {
        try {
            const token = window.localStorage.getItem("access_token");
            const response = await axios.get("Painel/GetHistoricoTelefonia", {
                baseURL: api.urlBase,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setHistoryHistoricoTelefonia(response.data || []);
        } catch (error){
            console.log("Erro ao obter historico telefonia:", error);
        }        
    };

    const GetEnriquecimento = async () => {
        try {
            const token = window.localStorage.getItem("access_token");
            const response = await axios.get("Painel/GetEnriquecimento", {
                baseURL: api.urlBase,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setEnriqueimento(response.data || []);
        } catch (error){
            console.log("Erro ao obter enriquecimento:", error);
        }        
    };

    useEffect(() => {
        const carregarDados = () => {
            GetHistoryImportaBase();
            GetHistoricoTelefonia();
            GetEnriquecimento();
            GetStatusBatch();
        };
        carregarDados();

        const intervalo = setInterval(carregarDados, 5000);

        return () => clearInterval(intervalo);
    }, []);

    const formatTimeUnit = (unit) => unit.toString().padStart(2, '0');

    return (
        <div>
            <div className="card-painel-container">
                <div className="card-painel">
                    <span>Batch Importar Base</span>
                    <p>{statusBatchImportaBase ? <span className='ativo-painel'><HiOutlineStatusOnline /> ATIVO</span> : <span className='inativo-painel'><HiOutlineStatusOffline /> INATIVO</span>}</p>
                    {!statusBatchImportaBase && <button onClick={() => StartBatch("Telefonia.Worker.Host")} className='btn btn-primary'>Ligar Batch</button>}
                </div>
                <div className="card-painel">
                    <span>Batch Historico Telefonia</span>
                    <p>{statusBatchHistoricoTelefonia ? <span className='ativo-painel'><HiOutlineStatusOnline /> ATIVO</span> : <span className='inativo-painel'><HiOutlineStatusOffline /> INATIVO</span>}</p>
                    {!statusBatchHistoricoTelefonia && <button onClick={() => StartBatch("BatchPreTelefonia")} className='btn btn-primary'>Ligar Batch</button>}
                </div>
                <div className="card-painel">
                    <span>Batch Score</span>
                    <p>{statusBatchScore ? <span className='ativo-painel'><HiOutlineStatusOnline /> ATIVO</span> : <span className='inativo-painel'><HiOutlineStatusOffline /> INATIVO</span>}</p>
                    {!statusBatchScore && <button onClick={() => StartBatch("BatchScoreOcorrencia")} className='btn btn-primary'>Ligar Batch</button>}
                </div>
            </div>
            <br />

            <div className="container">
                <div style={styles.header}>
                    <button
                        style={{ ...styles.pageButton, ...(activePage === 1 ? styles.activeButton : {}) }}
                        onClick={() => setActivePage(1)}
                    >
                        Processos Importar Base
                    </button>
                    <button
                        style={{ ...styles.pageButton, ...(activePage === 2 ? styles.activeButton : {}) }}
                        onClick={() => setActivePage(2)}
                    >
                        Processos Historico Telefonia
                    </button>
                    <button
                        style={{ ...styles.pageButton, ...(activePage === 3 ? styles.activeButton : {}) }}
                        onClick={() => setActivePage(3)}
                    >
                        Processos Enriquecimento
                    </button>
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {activePage === 1 && <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Usuário</th>
                                <th>Empresa</th>
                                <th>Nome do arquivo</th>
                                <th>Data de envio</th>
                                <th>Tempo processamento</th>
                                <th>Status</th>
                                <th>Total linhas</th>
                                <th>Total Telefones</th>
                                <th>Total Telefones importados</th>
                                <th>Existentes Telefonia</th>
                                <th>Registros Com Erro</th>
                                <th>Layout</th>
                                <th>Percentual</th>
                                {/* <th>Log</th> */}
                            </tr>
                        </thead >
                        <tbody>
                            {historyImportaBase.map((item, i) => {
                                const date = new Date(item.data_Cadastro);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = String(date.getHours()).padStart(2, '0');
                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                const seconds = String(date.getSeconds()).padStart(2, '0');
                                const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                const now = new Date();
                                const inicioProcessamento = new Date(item.inicio_Processamento);
                                const diffMs = now - inicioProcessamento;

                                const diffHours = Math.floor(diffMs / 3600000);
                                const diffMinutes = Math.floor((diffMs % 3600000) / 60000);
                                const diffSeconds = Math.floor((diffMs % 60000) / 1000);

                                const formattedDiffHours = formatTimeUnit(diffHours);
                                const formattedDiffMinutes = formatTimeUnit(diffMinutes);
                                const formattedDiffSeconds = formatTimeUnit(diffSeconds);

                                const tempoProcessamento = `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`;


                                return (
                                    <tr key={i}>
                                        <td>{item.nomeUsuario}</td>
                                        <td>{item.empresaUsuario}</td>
                                        <td>{item.nome_Arquivo}</td>
                                        <td>{formattedDateTime}</td>
                                        <td>{tempoProcessamento}</td>
                                        <td>{item.statusProcessamento === 5 ? "Lendo Arquivo" : item.statusProcessamento === 0 ? "Aguardando Processamento" : item.statusProcessamento === 1 ? " Em Processamento" : item.statusProcessamento === 2 ? "Importado" : "Erro"}</td>
                                        <td>{item.quantidadeLinhasArquivo === null ? "-" : item.quantidadeLinhasArquivo}</td>
                                        <td>{item.quantidadeTelefonesArquivo == null ? "-" : item.quantidadeTelefonesArquivo}</td>
                                        <td>{item.statusProcessamento === 0 || item.statusProcessamento === 1 ? "-" : item.total_Inserts}</td>
                                        <td>{item.registros_Existente_Telefonia === 0 ? "-" : item.registros_Existente_Telefonia}</td>
                                        <td>{item.quantidade_Registros_Erro}</td>
                                        <td>{item.tipo_Layout === null ? "-" : item.tipo_Layout}</td>
                                        <td>{item.porcentagem_Achados === null ? "-" : item.porcentagem_Achados + "%"}</td>
                                        {/* <td>{item.quantidade_Registros_Erro === 0 ? "-" : <button onClick={() => GetFileLog(item.id)}><FaFile size={20} /></button>}</td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table >}
                    {activePage === 2 && <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Usuário</th>
                                <th>Empresa</th>
                                <th>Nome do arquivo</th>
                                <th>Data de envio</th>
                                <th>Tempo Processamento</th>
                                <th>Status</th>
                                <th>Total linhas</th>
                                <th>Total Telefones</th>
                                <th>Total Telefones importados</th>
                                <th>Existentes Telefonia</th>
                                <th>Registros Com Erro</th>
                                {/* <th>Log</th> */}
                            </tr>
                        </thead >
                        <tbody>
                            {historyHistoricoTelefonia.map((item, i) => {
                                const date = new Date(item.data_Cadastro);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = String(date.getHours()).padStart(2, '0');
                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                const seconds = String(date.getSeconds()).padStart(2, '0');
                                const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                const now = new Date();
                                const inicioProcessamento = new Date(item.inicio_Processamento);
                                const diffMs = now - inicioProcessamento;

                                const diffHours = Math.floor(diffMs / 3600000);
                                const diffMinutes = Math.floor((diffMs % 3600000) / 60000);
                                const diffSeconds = Math.floor((diffMs % 60000) / 1000);

                                const formattedDiffHours = formatTimeUnit(diffHours);
                                const formattedDiffMinutes = formatTimeUnit(diffMinutes);
                                const formattedDiffSeconds = formatTimeUnit(diffSeconds);

                                const tempoProcessamento = `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`;

                                return (
                                    <tr key={i}>
                                        <td>{item.nomeUsuario}</td>
                                        <td>{item.empresaUsuario}</td>
                                        <td>{item.nome_Arquivo}</td>
                                        <td>{formattedDateTime}</td>
                                        <td>{tempoProcessamento}</td>
                                        <td>{item.statusProcessamento === 5 ? "Lendo Arquivo" : item.statusProcessamento === 0 ? "Aguardando Processamento" : item.statusProcessamento === 1 ? " Em Processamento" : item.statusProcessamento === 2 ? "Importado" : "Erro"}</td>
                                        <td>{item.quantidadeLinhasArquivo === null ? "-" : item.quantidadeLinhasArquivo}</td>
                                        <td>{item.quantidadeTelefonesArquivo == null ? "-" : item.quantidadeTelefonesArquivo}</td>
                                        <td>{item.statusProcessamento === 0 || item.statusProcessamento === 1 ? "-" : item.total_Inserts}</td>
                                        <td>{item.registros_Existente_Telefonia === 0 ? "-" : item.registros_Existente_Telefonia}</td>
                                        <td>{item.quantidade_Registros_Erro}</td>
                                        {/* <td>{item.quantidade_Registros_Erro === 0 ? "-" : <button onClick={() => GetFileLog(item.id)}><FaFile size={20} /></button>}</td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table >}
                    {activePage === 3 && <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Usuário</th>
                                <th>Empresa</th>
                                <th>Nome do arquivo</th>
                                <th>Data Consulta</th>
                                <th>Tempo Processamento</th>
                                <th>Status</th>
                                <th>Total</th>
                                <th>Total Cpfs Achados</th>
                                <th>Total Cpfs Não Achados</th>
                                <th>Total Telefones Achados</th>
                                <th>Porcentagem Cpf's achados</th>
                                <th>Resultado</th>
                                <th>Dinâmico</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enriquecimento.map((item, i) => {

                                const date = new Date(item.data_Cadastro);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = String(date.getHours()).padStart(2, '0');
                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                const seconds = String(date.getSeconds()).padStart(2, '0');

                                const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

                                const now = new Date();
                                const inicioProcessamento = new Date(item.inicio_Processamento);
                                const diffMs = now - inicioProcessamento;

                                const diffHours = Math.floor(diffMs / 3600000);
                                const diffMinutes = Math.floor((diffMs % 3600000) / 60000);
                                const diffSeconds = Math.floor((diffMs % 60000) / 1000);

                                const formattedDiffHours = formatTimeUnit(diffHours);
                                const formattedDiffMinutes = formatTimeUnit(diffMinutes);
                                const formattedDiffSeconds = formatTimeUnit(diffSeconds);

                                const tempoProcessamento = `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`;


                                return (
                                    <tr key={item.id}>
                                        <td>{item.nomeUsuario}</td>
                                        <td>{item.empresaUsuario}</td>
                                        <td>{item.nome_Arquivo}</td>
                                        <td>{formattedDateTime}</td>
                                        <td>{tempoProcessamento}</td>
                                        <td>{item.statusProcessamento === 0 ? "Pendente"
                                            : item.statusProcessamento === 1 ? "Processando"
                                                : item.statusProcessamento === 2 ? "Importado"
                                                    : item.statusProcessamento === 3 ? "Alerta"
                                                        : item.statusProcessamento === 4 ? "Erro"
                                                            : item.statusProcessamento === 4 ? "Lendo Arquivo" : "Importado"}</td>
                                        <td>{item.total_Inserts}</td>
                                        <td>{item.total_Achados}</td>
                                        <td>{item.total_Nao_Achados}</td>
                                        <td>{item.quantidade_Telefones_Achados}</td>
                                        <td>{item.porcentagem_Achados}%</td>
                                        <td>{item.enriquecimento_Por_Linha === null ? "-" : item.enriquecimento_Por_Linha === true ? "Linha" : "Coluna"}</td>
                                        <td>{item.manter_Arquivo_Enriquecimento == null ? "-" : item.manter_Arquivo_Enriquecimento === true ? "Manter Arquivo" : "Dinâmico"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>}
                </div>
            </div >
        </div>
    );
}

export default Painel;
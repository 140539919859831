import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoIosClose } from "react-icons/io";
import { MdChecklist } from "react-icons/md";
import { MdPlaylistRemove } from "react-icons/md";
import ButtonWithTooltip from './buttonToolTip';

const DragAndDropDiv = ({
    unCheckAllCheckboxes, checkAllCheckboxes, onDeleteValues, onApprovalValues
}) => {
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        setDragging(true);
        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y
            });
        }
    };

    return (
        <div
            style={{
                width: '200px',
                height: 'auto',
                backgroundColor: 'lightblue',
                position: 'absolute',
                top: position.y + 'px',
                left: position.x + 'px',
                cursor: dragging ? 'grabbing' : 'grab',
                padding: '1rem',
                borderRadius: '20px'
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
        >
            <div style={{ gap: '1rem', flexDirection: 'column', padding: 10, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <small>Funções</small>
                <small style={{ fontSize: 10,marginTop:-10 }}>Arraste-me</small>
                <div style={{ borderBottom: '1px solid #000', width: '100%' }}></div>
                <div className="row">
                    <div className="col-md-6">
                        <ButtonWithTooltip
                            top={true}
                            text={'Deselecionar página atual.'}
                            textButton={<MdPlaylistRemove size={30} />}
                            className='btn btn-info'
                            onClick={unCheckAllCheckboxes}
                        />
                    </div>
                    <div className="col-md-6">
                        <ButtonWithTooltip
                            top={true}
                            className='btn btn-secondary'
                            onClick={checkAllCheckboxes}
                            textButton={<MdChecklist size={30} />}
                            text={'Selecionar página atual.'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ButtonWithTooltip
                            className='btn btn-danger'
                            onClick={onDeleteValues}
                            textButton={<IoIosClose size={30} />}
                            text={'Reprovar Selecionados.'}
                        />
                    </div>
                    <div className="col-md-6">
                        <ButtonWithTooltip
                            className='btn btn-success'
                            onClick={onApprovalValues}
                            textButton={<FaCheck size={30} />}
                            text={'Aprovar Selecionados.'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DragAndDropDiv;

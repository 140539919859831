import { Component, useEffect } from 'react';
import React from 'react';
import Pagination from '../../components/Pagination/pagination';
import Details from './details';

export default class QuarantineTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedsId: []
        }

        this.ReloadTable = (selecteds) => { this.setState({ selectedsId: selecteds }) }
    }

    render() {

        const { unCheckAllCheckboxes, checkAllCheckboxes, onReload, onApprovalValues,
            refPagination, records = [], setRecords,
            quarantine = [], onChangeChecked, onDeleteValues } = this.props;

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {quarantine.length === 0 && <span> Não há dados em quarentena.</span>}
                {
                    quarantine.length > 0 && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <table className='table table-striped'>
                            <thead>
                                <tr>

                                    <th style={{ textAlign: 'center' }}>Selecionado</th>
                                    <th style={{ textAlign: 'center' }}>Id</th>
                                    <th style={{ textAlign: 'center' }}>CPF</th>
                                    <th style={{ textAlign: 'center' }}>DDD</th>
                                    <th style={{ textAlign: 'center' }}>Telefone</th>
                                    <th style={{ textAlign: 'center' }}>Data da Discagem</th>
                                    <th style={{ textAlign: 'center' }}>Ocorrencia</th>
                                    <th style={{ textAlign: 'center' }}>Ocorrencia_final</th>
                                    <th style={{ textAlign: 'center' }}>Ver Detalhes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {records.map((item, i) => {
                                    let date = new Date(item.data_discagem);
                                    var dateString = date.getUTCDate() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCFullYear();

                                    var ckd = this.state.selectedsId.filter(x => x === parseInt(item.id)).length > 0;
                                    return (
                                        <tr key={i}>

                                            <td style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: "center" }}><input checked={ckd} name={`Id-${item.id}`} onChange={(e) => onChangeChecked(e, item.id)} type='checkbox' /></td>
                                            <td style={{ textAlign: 'center' }}>{item.id}</td>
                                            <td style={{ textAlign: 'center' }}>{item.cpf}</td>
                                            <td style={{ textAlign: 'center' }}>{item.ddd}</td>
                                            <td style={{ textAlign: 'center' }}>{item.telefone}</td>
                                            <td style={{ textAlign: 'center' }}>{dateString}</td>
                                            <td style={{ textAlign: 'center' }}>{item.ocorrencia}</td>
                                            <td style={{ textAlign: 'center' }}>{item.ocorrencia_final}</td>
                                            <td style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: "center" }}><Details onReload={onReload} id={item.id} /></td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                <Pagination
                    ref={refPagination}
                    data={quarantine}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div >
        );
    }
}
import React, { useEffect, useState } from 'react';
import './home.css';
import { FaUserCircle } from 'react-icons/fa';
import { api } from '../../components/Api/api';

import { Bar } from '../../components/Chart/Bar';

function Home() {
    const [usuarios, setUsuarios] = useState([]);
    // const [historicoDia, setHistoricoDia] = useState([]);
    const [historicoMes, setHistoricoMes] = useState([]);
    const meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    function getFirstDayOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // getMonth() retorna de 0 a 11
        return `${year}-${String(month).padStart(2, '0')}-01`;
    }

    function getLastDayOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const lastDay = new Date(year, month, 0).getDate();
        return `${year}-${String(month).padStart(2, '0')}-${String(lastDay).padStart(2, '0')}`;
    }

    const [dtInicio, setDtInicio] = useState(getFirstDayOfCurrentMonth());
    const [dtFim, setDtFim] = useState(getLastDayOfCurrentMonth());

    const BuscarData = () => {
        api.get(`DashBoard/Home?dtInicio=${dtInicio}&dtFim=${dtFim}`, res => {
            setUsuarios(res.data.usuarios);
            // setHistoricoDia(res.data.historicoPorDia);
            setHistoricoMes(res.data.historicoPorMes);
        }, console.log(err => console.log(err)));
    }

    useEffect(() => {
        BuscarData();
    }, [])

    return (
        <div>
            <div className='row'>
                <div className="col-md-2"></div>
                <div className="col-md-4">
                    <label>De:</label>
                    <input value={dtInicio} onChange={e => setDtInicio(e.target.value)} type="date" className='form-control' />
                </div>
                <div className="col-md-4">
                    <label>Até:</label>
                    <input value={dtFim} onChange={e => setDtFim(e.target.value)} type="date" className='form-control' />
                </div>
                <div className="col-md-2" style={{ marginTop: 22 }}>
                    <button onClick={BuscarData} className='btn btn-primary'>Buscar</button>
                </div>
            </div>
            <div className="container-home">
                <div className="container-left-home">
                    {/* <div className="container-grafico-home">
                        <h2>Total arquivos processadors por dia</h2>
                        <Bar
                            Categories={historicoDia.map(x => x.dia)}
                            Data={historicoDia.map(x => x.quantidade)}
                        />
                    </div> */}
                    <div className="container-grafico-home">
                        <h2>Total arquivos importados no mês</h2>
                        <Bar
                            Categories={historicoMes.map(x => meses[x.mes - 1])}
                            Data={historicoMes.map(x => x.quantidade)}
                        />
                    </div>
                </div>
                <div className="container-right-home">
                    <h2>Usuários</h2>
                    {usuarios.map(usuario => (
                        <div class="box-home">
                            <FaUserCircle size={40} />
                            <div class="box-content-home">
                                <div class="box-header-home">
                                    <div class="name">{usuario.nome}</div>
                                    <div class="name">{usuario.tipoUsuario}</div>
                                </div>
                                <p class="box-line-home">
                                    Arquivos Importados: {usuario.arquivosImportados}
                                    <br />
                                    Arquivos Com Erro: {usuario.arquivosErro}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    );
}

export default Home;
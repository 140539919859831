import React, { Component } from 'react';
import './loading.css';

export class Loading extends Component {
    render() {
       
        if (typeof (window.waitbool) == 'undefined')
            window.waitbool = true; 
            
        window.Wait =function(showit) 
        {
            if(showit != undefined) window.waitbool = !showit;
            window.waitbool = !window.waitbool;

            if (document.getElementById('loadingpanel') != null) {
                
                if (window.waitbool)
                    document.getElementById('loadingpanel').style.display = 'block';
                else {
                    document.getElementById('loadingpanel').style.display = 'none';
                }
            }
            return window.waitbool;
        }
         
        return (
            <div id="loadingpanel" style={{ display: 'none' }} >
                <div className="loadingBg"></div>
                <div className="container">
                    <div className="row">
                        <div id="loading">
                            <ul className="bokeh">
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
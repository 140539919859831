import React from 'react';

export default ({ companys = [], selectCompany }) => {
    return (
        <div className='container'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: '1px dashed #000',padding:10 }}>

                <table style={{ width: '50%' }} className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companys.map(item => (
                            <tr>
                                <td>{item.empresaId}</td>
                                <td>{item.nomeEmpresa}</td>
                                <td><button onClick={() => selectCompany(item.empresaId)} className='btn btn-primary'>Selecionar</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
import React, { useState, useEffect } from 'react'
import '../../Util/Dash.css';
import Layout from '../../components/Layout/layout';
import { Bar } from '../../components/Chart/Bar';
import { api } from '../../components/Api/api';
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import { FaPhone, FaSkullCrossbones } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";

function DashHistorico() {
    const [collapse1, setCollapse1] = useState(false);
    const [collapse2, setCollapse2] = useState(false);
    const [collapse3, setCollapse3] = useState(false);
    const [collapse5, setCollapse5] = useState(false);
    const [collapse6, setCollapse6] = useState(false);

    const [qtdHistoricoTelefonia, setQtdHistoricoTelefonia] = useState(0);
    const [qtdRegistroQuarentena, setQtdRegistroQuarentena] = useState(0);

    const [cincoOcorrencias, setCincoOcorrencias] = useState([])
    const [qtdTestes, setQtdTestes] = useState([]);
    const [qtdRegistrosImportados, setQtdRegistrosImportados] = useState([]);
    const [qtdOrigemHistorico, setQtdOrigemHistorico] = useState([]);
    const [totalInsertPorArquivo, setTotalInsertPorArquivo] = useState([]);

    const [cpf, setCpf] = useState('');
    const [date1, setdate1] = useState('');
    const [date2, setdate2] = useState('');

    const GetData = () =>
    {
        api.get(`BuscarDashHistorico?Cpf=${cpf}&Datai=${date1}&Dataf=${date2}`, res => {
            setQtdHistoricoTelefonia(res.data.qtdHistoricoTelefonia);
            setQtdRegistroQuarentena(res.data.qtdRegistroQuarentena);
            setCincoOcorrencias(res.data.registroMaisDe5OcorrenciasIguais);
            setQtdTestes(res.data.qtdTestes);
            setQtdOrigemHistorico(res.data.qtdOrigemHistorico);
            setQtdRegistrosImportados(res.data.qtdRegistrosImportados);
            setTotalInsertPorArquivo(res.data.totalInsertPorArquivo);
        })
    }

    useEffect(() => {
        GetData()       
    }, [])

    const extractData = () => {
        api.get(`BaixarDash?Cpf=${cpf}&Datai=${date1}&Dataf=${date2}&DashHistorico=true`, res => {
            DownloadFile(res.data);
        });
    }

    const DownloadFile = (path) => {
        let csvContent = atob(path);
        var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url
        a.download = "retorno.csv";
        a.click();
    }

    return (
        <Layout>
            <section className='container'>
                <div className='container-chart'>
                    <h5>Filtros:</h5>
                    <div className="row">
                        <div className="col-4" style={{ backgroundColor: '#fff', borderRadius: 10, margin: 5 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                <span style={{ marginRight: 5 }}>de</span>
                                <input onChange={e => setdate1(e.target.value)} style={{ marginRight: 5 }} className='form-control' type="date" name="" id="" />
                                <span style={{ marginRight: 5 }}>até</span>
                                <input onChange={e => setdate2(e.target.value)} style={{ marginRight: 5 }} className='form-control' type="date" name="" id="" />
                            </div>
                        </div>
                        <div className="col-4" style={{ backgroundColor: '#fff', borderRadius: 10, margin: 5 }}>
                            <span>Pelo Cpf:</span>
                            <input onChange={e => setCpf(e.target.value)} className='form-control' placeholder='XXX.XXX.XXX-XX' type="text" name="" id="" />
                        </div>
                        <div className="col-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button type="button"
                                onClick={GetData}
                                className='btn btn-primary'>
                                FILTRAR</button>
                            <button type="button"
                                onClick={extractData}
                                className='btn btn-warning'>Extrair dados</button>
                        </div>
                    </div>
                    <hr />
                    <h5>Cards:</h5>
                    <div className="row">
                        <div className="col-6" style={{ textAlign: 'center', backgroundColor: '#fff', borderRadius: 10, margin: 5 }}>
                            <span style={{ fontWeight: 'bold' }}>Quantidade de registro Histórico da Telefonia</span>
                            <hr />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                <FaHistory size={35} />
                                <h3>{qtdHistoricoTelefonia}</h3>
                            </div>
                        </div>
                        <div className="col-5" style={{ textAlign: 'center', backgroundColor: '#fff', borderRadius: 10, margin: 5 }}>
                            <span style={{ fontWeight: 'bold' }}>Quantidade de registro Quarentena</span>
                            <hr />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, textAlign: 'center' }}>
                                <FaSkullCrossbones size={35} />
                                <h3>{qtdRegistroQuarentena}</h3>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h5>Grafícos:</h5>
                    <div className="row">
                        <div className="col-12" style={{ margin: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                            <span className='title-collapse-dashboard' onClick={() => setCollapse6(!collapse6)}>TOP 10 quantidade de registro por arquivo {collapse6 ? <MdArrowDropUp size={25} /> : <MdArrowDropDown size={25} />} </span>
                            {collapse6 && <Bar categories={totalInsertPorArquivo.map(x => x.nome_Arquivo)}
                                data={cincoOcorrencias.map(x => x.total_Inserts)} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ margin: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                            <span className='title-collapse-dashboard' onClick={() => setCollapse1(!collapse1)}>Registro para mais de uma empresa e com mais de 5 ocorrencias iguais {collapse1 ? <MdArrowDropUp size={25} /> : <MdArrowDropDown size={25} />} </span>
                            {collapse1 && <Bar categories={cincoOcorrencias.map(x => x.name)}
                                data={cincoOcorrencias.map(x => x.data)} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ margin: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                            <span className='title-collapse-dashboard' onClick={() => setCollapse5(!collapse5)} >  Origem {collapse5 ? <MdArrowDropUp size={25} /> : <MdArrowDropDown size={25} />} </span>
                            {collapse5 && <Bar categories={qtdOrigemHistorico.map(x => x.origem)}
                                data={qtdOrigemHistorico.map(x => x.quantidade)} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ margin: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                            <span className='title-collapse-dashboard' onClick={() => setCollapse2(!collapse2)} >  Top 10 maiores em ligações de testes  {collapse2 ? <MdArrowDropUp size={25} /> : <MdArrowDropDown size={25} />} </span>
                            {collapse2 && <Bar categories={qtdTestes.map(x => x.name)}
                                data={qtdTestes.map(x => x.data)} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ margin: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                            <span className='title-collapse-dashboard' onClick={() => setCollapse3(!collapse3)} >  Quantidade de registros importados para a tabela Histórico Telefonia e Quarentena (por usuário e empresa) {collapse3 ? <MdArrowDropUp size={25} /> : <MdArrowDropDown size={25} />} </span>
                            {collapse3 && <Bar mapData={false} horizontal={false} categories={qtdRegistrosImportados.map(x => x.name)}
                                data={qtdRegistrosImportados.map(x => x.total_Inserts)} />}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>)
}

export default DashHistorico;
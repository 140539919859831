import React from 'react';
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import SelectCompany from './selectCompany';
import QuarantineTable from './quarantineTable';
import Filter from './filter';
import DragAndDropDiv from './options';

function Approval() {
    const [quarantine, setQuarantine] = useState([]);
    const [quarantineFilter, setFilterQuarantine] = useState([]);
    const [records, setRecords] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [company, setCompany] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const refPagination = useRef();
    const refTable = useRef();
    const [groupedByOcorrencias, setOcorrencias] = useState([]);

    const [filterCpf, setFilterCpf] = useState('');
    const [filterOcorrencia, setFilterOcorrencia] = useState('');
    const [filterDtInit, setFilterDtInit] = useState('');
    const [filterDtEnd, setFilterDtEnd] = useState('');

    const getCompany = () => {
        api.get(`Quarentena/GetEmpresaQuarentena`, res => {
            setCompany(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    useEffect(() => {
        getCompany();
    }, [])

    const selectCompany = (id) => {
        api.get(`Quarentena/GetQuarentena/${id}`, res => {
            setRecords([]);
            setQuarantine(res.data.historicoQuarentenaList);
            setFilterQuarantine(res.data.historicoQuarentenaList);

            refPagination.current.Reload(res.data.historicoQuarentenaList);
            uncheckCheckboxes();
            setOcorrencias(res.data.ocorrencias);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })

        setSelectedCompany(id);
    };

    const onChangeChecked = (e, id) => {
        let copy = selecteds;
        var converted = parseInt(id);
        if (copy.filter(x => x == converted).length > 0) {
            let index = selecteds.indexOf(converted);
            copy.splice(index, 1);
        } else {
            copy.push(converted);
        }
        setSelecteds(copy);
        refTable.current.ReloadTable(copy);
    }

    const onDeleteValues = () => {
        if (selecteds.length == 0) {
            openAlert('Selecione pelo menos 1.', false, true);
            return;
        }
        if (window.confirm("Deseja realmente remover os itens selecionados?")) {
            api.post(`Quarentena/ReprovarRegistrosQuarentena`, selecteds, res => {
                setSelecteds([]);
                selectCompany(selectedCompany);
                refPagination.current.Reload(quarantine);
            }, erro => {
                openAlert('Algo deu errado\n erro:' + erro, false)
            })
        }
    }

    const onApprovalValues = () => {
        if (selecteds.length == 0) {
            openAlert('Selecione pelo menos 1.', false, true);
            return;
        }
        if (window.confirm("Deseja realmente aprovar os itens selecionados?")) {
            api.post(`Quarentena/AprovarRegistrosQuarentena`, selecteds, res => {
                setSelecteds([]);
                selectCompany(selectedCompany);
                refPagination.current.Reload(quarantine);
            }, erro => {
                openAlert('Algo deu errado\n erro:' + erro, false)
            })
        }
    }

    function uncheckCheckboxes() {
        const table = document.getElementById('customers');
        if (table) {
            const checkboxes = table.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
            });
        }
    }

    function checkAllCheckboxes() {
        const list = selecteds;
        records.forEach(item => {
            list.push(item.id);
        })
        setSelecteds(list);
        refTable.current.ReloadTable(list);
    }

    function unCheckAllCheckboxes() {
        const list = [];
        setSelecteds(list);
        refTable.current.ReloadTable(list);
    }

    const reload = () => selectCompany(selectedCompany);

    const onChangeFilter = (e, id) => {
        let value = e.target.value;
        if (id === "CPF")
            setFilterCpf(value)
        if (id === "OCORRENCIA")
            setFilterOcorrencia(value)
        if (id === "DATACOMECO")
            setFilterDtInit(value);
        if (id === "DATAFIM")
            setFilterDtEnd(value);
    }

    const onSubmitFilter = () => {
        api.get(`Quarentena/GetFilterQuarentena?empresaId=${selectedCompany}&filtroCpf=${filterCpf}&filtroOcorrencia=${filterOcorrencia}&filtroDtInicio=${filterDtInit}&filtroFim=${filterDtEnd}`, res => {
            setRecords([]);
            setQuarantine(res.data.historicoQuarentenaList);
            setFilterQuarantine(res.data.historicoQuarentenaList);
            refPagination.current.Reload(res.data.historicoQuarentenaList, true);
            uncheckCheckboxes();
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    return (
        <section>
            {selectedCompany > 0 && <DragAndDropDiv
                onDeleteValues={onDeleteValues}
                onApprovalValues={onApprovalValues}
                checkAllCheckboxes={checkAllCheckboxes}
                unCheckAllCheckboxes={unCheckAllCheckboxes}
            />}
            <br />
            <center>
                <h1>Aprovação Quarentena</h1>
            </center>
            <hr />
            {selectedCompany == 0
                && <center>
                    <br />
                    <h2>Selecione uma empresa.</h2>
                    <br />
                </center>}
            {selectedCompany == 0
                && <SelectCompany
                    selectCompany={selectCompany}
                    companys={company} />}

            {selectedCompany > 0 &&
                <Filter
                    onSubmitFilter={onSubmitFilter}
                    onChangeFilter={onChangeFilter}
                    groupedByOcorrencias={groupedByOcorrencias}
                />}
            {selectedCompany > 0 &&
                <QuarantineTable
                    refPagination={refPagination}
                    setRecords={setRecords}
                    quarantine={quarantineFilter}
                    records={records}
                    onChangeChecked={onChangeChecked}
                    onDeleteValues={onDeleteValues}
                    onApprovalValues={onApprovalValues}
                    onReload={reload}
                    selecteds={selecteds}
                    ref={refTable}
                    checkAllCheckboxes={checkAllCheckboxes}
                    unCheckAllCheckboxes={unCheckAllCheckboxes}
                />}
        </section>
    );
}

export default Approval;
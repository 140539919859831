import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import './style.css'
import Pagination from '../../components/Pagination/pagination';
import EditOperador from '../../components/Modals/EditOperador';
import { Collapse } from 'react-bootstrap';

function RegisterOperador() {
    const [cpf, setCpf] = useState('');
    const [OperadorName, setOperadorName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [registered, setRegistred] = useState(false);

    const [records, setRecords] = useState([]);
    const refPagination = useRef();
    const refEdit = useRef();
    const [operadors, setOperadors] = useState([]);
    const [oldOperadors, setOldOperadors] = useState([]);

    const [openRegister, setOpenRegister] = useState(false);

    const [registeredUser, setRegistredUser] = useState({
        email: '', psw: ''
    });


    const GetOperadors = () => {
        api.get("usuario/Operador", res => {
            setOperadors(res.data)
            setOldOperadors(res.data)
            if (refPagination)
                if (refPagination.current)
                    if (refPagination.current.Reload)
                        refPagination.current.Reload(res.data);
        }, erro => {
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    useEffect(GetOperadors, []);

    const isNum = (value) => {
        value = value.replaceAll('.', '').replaceAll('-', '')
            .replaceAll('/', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
        var er = /[^0-9.]/;
        er.lastIndex = 0;
        if (er.test(value)) {
            return false;
        }
        return true;
    }

    const maskPhone = (e) => {
        if (isNum(e.target.value)) {
            setPhone(e.target.value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"))
        }
    }

    const maskCpf = (e) => {
        if (isNum(e.target.value)) {
            setCpf(e.target.value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"))
        }
    }

    const submit = () => {
        api.post("/Usuario/CadastroOperador", {
            Email: email,
            Cpf: cpf,
            NomeOperador: OperadorName,
            Telefone: phone,
        }, res => {
            openAlert('Cadastro Realizado com sucesso!\nFoi enviado as credenciais de acesso no email: ' + email);
            GetOperadors();
        }, erro => {
            setRegistred(false)
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    const inactivateOperador = (id) => {
        if (window.confirm("Deseja realmente inativar esse operador?")) {
            const data = new FormData()
            data.append("OperadorId", parseInt(id))
            api.post("Usuario/InativarOperador", data, res => {
                GetOperadors();
                openAlert("Operador inativado com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const activateOperador = (id) => {
        if (window.confirm("Deseja realmente ativar esse Operador?")) {
            const data = new FormData()
            data.append("OperadorId", parseInt(id))
            api.post("Usuario/AtivarOperador", data, res => {
                GetOperadors();
                openAlert("Operador ativada com sucesso!")
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const filterNameOperador = (e) => {

        if (e.target.value == '') {
            refPagination.current.Reload(oldOperadors);
            return;
        }

        const result = operadors.filter(x => x.nomeOperador.toUpperCase().includes(e.target.value.toUpperCase()));

        if (result.length === 0) {
            refPagination.current.Reload([]);
            setRecords([])
            return;
        }

        refPagination.current.Reload(result);

    }

    const Edit = ({
        idOperador,
        operadorName,
        email,
        cpf,
        phone,
    }) => {
        api.put("Usuario/AtualizarOperador", {
            IdOperador: idOperador,
            NomeOperador: operadorName,
            EmailOperador: email,
            CpfOperador: cpf,
            TelefoneOperador: phone
        }, res => {
            openAlert("Operador atualizado com sucesso!")
            GetOperadors()
        }, erro => {
            if (erro.response.status === 400) {
                alert(erro.response.data, false)
            } else {
                alert(erro, false)
            }
        });
    }

    return (
        <>
            <EditOperador ref={refEdit} Edit={Edit} />
            <Collapse in={openRegister}>
                <form className='container box_shadow_form' style={{ padding: '35px', width: '40%', borderRadius: '10px', marginTop: '2rem' }} onSubmit={e => { e.preventDefault(); submit() }}>
                    <br />
                    <center>
                        <h1>Cadastrar Novo Operador</h1>
                        <small>Campos com * são obrigatórios</small>
                    </center>
                    <div className="row">
                        <div className="col-md-12">
                            <br />
                            <input value={OperadorName} onChange={e => setOperadorName(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome do operador*' type="text" className='form-control' required />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <br />
                            <input value={email} onChange={e => setEmail(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email da operador*' type="email" className='form-control' required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <br />
                            <input value={cpf} onChange={maskCpf} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Cpf*' type="text" className='form-control' required />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <input value={phone} onChange={maskPhone} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='DDD* + Telefone*' type="text" className='form-control' required />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: "end" }}>
                            <button style={{ width: '100%', backgroundColor: '#f2f2f2', color: '#000', height: '3.3rem' }} onClick={() => setOpenRegister(false)} className='btn'>FECHAR</button>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: "end" }}>
                            <button type="submit" className='btn btn-primary'>CADASTRAR</button>
                        </div>
                    </div>
                    <br />
                    <br />
                    {
                        registered && <div>
                            <h1>Dados de login da operador</h1>

                            <h3>Email: {registeredUser.email}</h3>
                            <h3>Senha: {registeredUser.psw}</h3>

                        </div>
                    }
                </form>
            </Collapse>
            {openRegister && <hr />}
            {!openRegister && <br />}
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '500px', flexDirection: 'column' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4" />
                        <div className="col-md-4">
                            <h3 style={{ textAlign: 'center' }}>Operadores cadastradas</h3>
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <button className='btn btn-success' onClick={() => setOpenRegister(!openRegister)}>Adicionar +</button>
                        </div>
                    </div>
                </div>
                {operadors.length === 0 && <span>Nenhuma operador cadastrada...</span>}
                {operadors.length > 0 && <br />}
                {operadors.length > 0 && <div style={{ display: 'flex' }}>
                    <input onChange={filterNameOperador} style={{ width: '17rem', border: '1px solid dodgerblue', padding: 10 }} type="text" className='form-control' placeholder='Filtrar pelo nome do operador' />
                </div>}
                {operadors.length > 0 && <br />}
                {operadors.length > 0 && <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Nome do operador</th>
                            <th>Email do operador</th>
                            <th>CPF do operador</th>
                            <th>DDD+TELEFONE</th>
                            <th>Status</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length === 0 && <td>Nenhuma operador encontrado...</td>}
                        {operadors.length > 0 &&
                            records.map(item => {
                                const operador = {
                                    idOperador: item.idOperador,
                                    operadorName: item.nomeOperador,
                                    email: item.emailOperador,
                                    cpf: item.cpfOperador,
                                    phone: item.telefoneOperador,
                                    status: item.statusOperador
                                }

                                return <tr>
                                    <td>{operador.operadorName}</td>
                                    <td>{operador.email}</td>
                                    <td>{operador.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                    <td>{operador.phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</td>
                                    <td>{operador.status ? "Ativo" : "Inativo"}</td>
                                    {operador.status && <td><button className='btn btn-success' onClick={() => refEdit.current.ToEdit(operador)} >Editar</button></td>}
                                    {operador.status && <td><button className='btn btn-danger' onClick={() => inactivateOperador(operador.idOperador)}>Inativar</button></td>}
                                    {!operador.status && <td><button className='btn btn-warning' onClick={() => activateOperador(operador.idOperador)}>Ativar</button></td>}
                                </tr>

                            })}
                    </tbody>
                </table>}
                <Pagination
                    ref={refPagination}
                    data={operadors}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
        </>
    );
}

export default RegisterOperador;
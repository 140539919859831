import React, { useState } from 'react';
import Header from '../Header/header'
import { LuBoxes } from "react-icons/lu";
export default ({ children, Tela }) => {
    const [open, setOpen] = useState(false);


    return (
        <Header>
            <div style={{ fontSize: 15, display: "flex", justifyContent: 'start', alignItems: 'center', gap: 10 }}>
                <span>{<LuBoxes size={15} color='#fff' />}</span>
                <span>{Tela}</span>
            </div>
            {children}
        </Header>
    );
};
import React, { Component, createRef, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../../Util/AlertUtil'
import { api } from '../../Api/api'
import AddLayout from './AddLayout'
import { FaGear } from "react-icons/fa6";

export default props => {
    const [show, setShow] = useState(false);
    const [layouts, setLayouts] = useState([]);

    const getMyLayouts = () => {
        api.get("File/BuscarTodosLayouts?layoutBase=true", res => {
            setLayouts(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    useEffect(() => {
        getMyLayouts();
    }, [])

    const handdleSelected = (e) => {
        const { setLayoutSelectedText, setLayoutSelectedId } = props;
        const { setSelectedLayout } = props;

        setLayoutSelectedId(e.target.value);
        var name = document.getElementById(e.target.id);
        setLayoutSelectedText(`${name[e.target.selectedIndex].text}`);
        setSelectedLayout(e.target.value);
    }


    return (<>

        <button onClick={() => setShow(true)} style={{ opacity: '0.7' }} class="btn btn-primary">Escolher layout</button>

        <Modal show={show}>
            {/* <form>  onSubmit={e => { e.preventDefault(); ()=>{} }} */}
            <Modal.Header>
                {layouts.length > 0 ? "Primeiro selecione o layout que vai ser importado" : "Adicione um novo layout"}
                {layouts.length > 0 && <button style={{ width: '10%', textAlign: 'center' }} className={"btn btn-warning"} onClick={() => window.location.href = '/addlayoutBase'}>+</button>}
                {layouts.length > 0 && <button style={{ marginLeft: 10, width: '10%', textAlign: 'center' }} className={"btn btn-warning"} onClick={() => window.location.href = '/tomanagerlayout?param=true'}><FaGear color='#fff' /></button>}
            </Modal.Header>

            <Modal.Body>
                {layouts.length == 0 && <button style={{ width: '100%', textAlign: 'center' }} className={"btn btn-link"} onClick={() => window.location.href = '/addlayoutBase'}>Clique aqui para adicionar um novo layout</button>}
                {layouts.length > 0 && <select onChange={handdleSelected} id="layouts" className='form-control'>
                    <option value={0} selected>-- SELECIONE --</option>
                    {
                        layouts.map(x => {
                            return (
                                <option value={x.id}>{x.tipo}</option>
                            );
                        })
                    }
                </select>}
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn btn-danger" onClick={() => setShow(false)}>Fechar</Button>
                {layouts.length > 0 && <Button className="btn btn-primary" onClick={() => setShow(false)}>Selecionar</Button>}
            </Modal.Footer>
        </Modal>
    </>)
}
import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import { FaPlus } from 'react-icons/fa';

class NewOcorrencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            points: 0,
            id: 0
        };
        this.ShowOcorrencia = (ocorrencia) => {
            this.setState({
                name: ocorrencia.ocorrencia_nome,
                points: ocorrencia.ocorrencia_score_id,
                id: ocorrencia.ocorrencia_id
            });
        }
    }

    onSubmit = () => {
        const { points, id } = this.state;
        const { Edit, BuscarOcorrencias } = this.props;
        if (points === 0) {
            alert('Selecione uma categoria');
            return;
        }

        api.post(Edit ? "EditarOcorrencia" : "CadastraOcorrencia", { Id: id, NomeScore: this.state.name, Valor: points }, res => {
            BuscarOcorrencias();
            openAlert(Edit ? "Ocorrencia Atualizado com sucesso!" : "Ocorrencia Cadastrado com sucesso!")
            this.props.setShowModal(false);
        }, erro => {
            openAlert("Houve um erro na solicitação: " + erro.data, false)
        })
    }

    closeModal = () => {
        const { Edit, Ocorrencia } = this.props;
        if (Edit && Ocorrencia) {
            this.setState({
                name: Ocorrencia.ocorrencia_nome,
                points: Ocorrencia.ocorrencia_pontos,
                id: Ocorrencia.ocorrencia_id
            });
        }
        this.props.setShowModal(false);
    }

    render() {
        const { showModal, Scores, Icon, Edit } = this.props;
        const { name, points } = this.state;
        return (
            <>
                <Modal show={showModal}>
                    <form onSubmit={e => { e.preventDefault(); this.onSubmit() }}>
                        <Modal.Header>
                            <h1>{Edit ? 'Editar' : 'Adicionar Nova'} Ocorrencia</h1>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={6}>
                                    <label>Nome:</label>
                                    <input required value={name} onChange={e => this.setState({ name: e.target.value })} type="text" placeholder='Nome' style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} />
                                </Col>
                                <Col md={6}>
                                    <label>Categoria:</label>
                                    <select value={points} onChange={e => this.setState({ points: e.target.value })} className='form-control' style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }}>
                                        <option value={0}>-- SELECIONE --</option>
                                        {Scores.map(score => (
                                            <option key={score.id} value={score.id}>{score.descricao}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <button type='button' style={{ width: '30%', padding: '15px' }} onClick={this.closeModal} className='btn btn-danger'>Voltar</button>
                            <button style={{ width: '30%' }} type='submit' className='btn btn-primary'>{Edit ? "Salvar" : "Cadastrar"}</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}

export default NewOcorrencia;

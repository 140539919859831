import React, { useEffect } from 'react';

function Filter(props) {
    const {
        groupedByOcorrencias = [],
        onChangeFilter,onSubmitFilter
    } = props;
    return (
        <div className="container">
            <h4>Filtros:</h4>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-md-3">
                    <span>De:</span>
                    <input type="date" className='form-control' onChange={e => onChangeFilter(e, "DATACOMECO")} />
                </div>
                <div className="col-md-3">
                    <span>Até:</span>
                    <input type="date" className='form-control' onChange={e => onChangeFilter(e, "DATAFIM")} />
                </div>
                <div className="col-md-3">
                    <span>Ocorrencia</span>
                    <span><select className='form-control' onChange={e => onChangeFilter(e, "OCORRENCIA")}>
                        <option value="">TODOS</option>
                        {groupedByOcorrencias && groupedByOcorrencias.map(item => (
                            <option value={item ? item : "0"}>{item ? item : "NULOS"}</option>
                        ))}
                    </select></span>
                </div>
                <div className="col-md-3">
                    <span>Cpf</span>
                    <span>
                        <select onChange={e => onChangeFilter(e, "CPF")} className='form-control'>
                            <option value="">TODOS</option>
                            <option value="1">NULOS</option>
                            <option value="2">PREENCHIDOS</option>
                        </select></span>
                </div>
            </div><br />
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3" style={{ display: 'flex', justifyContent: 'end' }}>
                    <button onClick={onSubmitFilter} className='btn btn-primary'>BUSCAR</button>
                </div>
            </div><br />
        </div>
    );
}

export default Filter;
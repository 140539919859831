import React, { Component } from 'react';
import { api } from '../../Api/api';
import { openAlert } from '../../../Util/AlertUtil';
import { Button, Modal } from 'react-bootstrap'

class ModalEmpresa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            companyName: '',
            email: '',
            cnpj: '',
            phone: '',
            contactEmail: '',
            name: '',
            emailNamePhone: '',
            idEmpresa: 0,
            isEdit: false
        }
        this.ToEdit = (id) => {
            api.get('Usuario/Empresa/' + id, res => {
                this.setState({
                    companyName: res.data.nomeEmpresa,
                    email: res.data.emailEmpresa,
                    cnpj: res.data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
                    phone: res.data.dddtelefone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
                    contactEmail: res.data.emailContato,
                    name: res.data.nome,
                    emailNamePhone: res.data.emailCobranca,
                    idEmpresa: res.data.idEmpresa,
                    show: true,
                    isEdit: true
                });
            }, err => openAlert(err.response.data, false));
        }
        this.Novo = () => this.setState({ show: true, isEdit: false });
    }

    handleModal() {
        this.setState(this.initialState);
    }

    initialState = {
        show: false,
        companyName: '',
        email: '',
        cnpj: '',
        phone: '',
        contactEmail: '',
        name: '',
        emailNamePhone: '',
        idEmpresa: 0,
        isEdit: false
    };

    render() {
        const {
            idEmpresa,
            companyName,
            email,
            cnpj,
            phone,
            contactEmail,
            name,
            emailNamePhone,
            isEdit
        } = this.state;

        const maskPhone = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ phone: e.target.value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") })
            }
        }

        const maskCnpj = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ cnpj: e.target.value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") })
            }
        }

        const isNum = (value) => {
            value = value.replaceAll('.', '').replaceAll('-', '')
                .replaceAll('/', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
            var er = /[^0-9.]/;
            er.lastIndex = 0;
            if (er.test(value)) {
                return false;
            }
            return true;
        }

        const handleSubmit = () => {
            if (isEdit) {
                api.put("Usuario/AtualizarEmpresa", {
                    IdEmpresa: idEmpresa,
                    NomeEmpresa: companyName,
                    EmailEmpresa: email,
                    CNPJ: cnpj,
                    DdddTelefone: phone,
                    EmailContato: contactEmail,
                    Nome: name,
                    EmailCobranca: emailNamePhone
                }, res => {
                    openAlert("Empresa atualizado com sucesso!")
                    this.props.AtualizarTela();
                    this.setState(this.initialState);
                }, erro => {
                    if (erro.response.status === 400) {
                        alert(erro.response.data)
                    } else {
                        alert(erro)
                    }
                });
            } else {
                api.post("/Usuario/CadastroEmpresa", {
                    NomeEmpresa: companyName,
                    Email: email,
                    CNPJ: cnpj,
                    Telefone: phone,
                    EmailContato: contactEmail,
                    Nome: name,
                    EmailAssociado: emailNamePhone
                }, res => {
                    openAlert('Cadastro Realizado com sucesso!\nFoi enviado as credenciais de acesso no email: ' + email);
                    this.props.AtualizarTela();
                    this.setState(this.initialState)
                }, erro => {
                    if (erro.response.status === 400) {
                        openAlert(erro.response.data, false)
                    } else {
                        openAlert(erro, false)
                    }
                });
            }
        }




        return (
            <Modal show={this.state.show}>
                <form onSubmit={e => {
                    e.preventDefault(); handleSubmit(); this.setState({ show: false })
                }}>
                    <Modal.Header>
                        <h3>{isEdit ? 'Editar' : 'Cadastrar'} Empresa</h3>
                        <Button onClick={() => { this.handleModal() }}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <small>Campos com * são obrigatórios</small>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 10 }}>
                                <label>Nome da empresa*</label>
                                <input value={companyName} onChange={e => this.setState({ companyName: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome da empresa*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <label>Email da empresa*</label>
                                <input value={email} onChange={e => this.setState({ email: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email da empresa*' type="email" className='form-control' required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Cnpj da empresa*</label>
                                <input value={cnpj} onChange={maskCnpj} minLength={18} maxLength={18} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='CNPJ*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>DDD+Telefone*</label>
                                <input value={phone} onChange={maskPhone} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='DDD* + Telefone*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Email de contato*</label>
                                <input value={contactEmail} onChange={e => this.setState({ contactEmail: e.target.value })} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email de contato*' type="email" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Nome*</label>
                                <input value={name} onChange={e => this.setState({ name: e.target.value })} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>Email de cobrança associado*</label>
                                <input value={emailNamePhone} onChange={e => this.setState({ emailNamePhone: e.target.value })} maxLength={100} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email de cobrança associado ao nome e telefone*' type="email" className='form-control' required />
                            </div>
                        </div>
                        <button className="btn btn-success" type="submit">{isEdit ? 'Editar' : 'Cadastrar'}</button>
                    </Modal.Body>
                </form>
            </Modal >
        );
    }
}

export default ModalEmpresa;
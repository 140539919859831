import React, { useState, useRef, useEffect } from 'react';
import Pagination from '../../components/Pagination/pagination'
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';
import EditScore from '../../components/Modals/EditScore';
import { Collapse } from '@mui/material';
export default _ => {

    const [records, setRecords] = useState([]);
    const refPagination = useRef();
    const refEdit = useRef();
    const [scores, setScores] = useState([]);
    const [oldScores, setOldscores] = useState([]);

    const [name, setName] = useState('');
    const [value, setValue] = useState(0);

    const [openRegister, setOpenRegister] = useState(false);

    const getscores = () => {
        api.get("BuscarTodosScore", res => {
            setScores(res.data)
            setOldscores(res.data)
            refPagination.current.Reload(res.data);
        }, erro => {
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    useEffect(() => {
        getscores();
    }, [])

    const registerScore = _ => {
        api.post("CadastraScore", {
            NomeScore: name,
            Valor: value
        }, res => {
            openAlert("Score cadastrado com sucesso!");
            getscores();
        }, erro => {
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    const deleteScore = id => {
        if (window.confirm("Deseja realmente excluir?")) {
            api.delete("ExcluirScore?id=" + id, res => {
                openAlert("Score deletado com sucesso!");
                getscores();
            }, erro => {
                if (erro.response.status === 400) {
                    openAlert(erro.response.data, false)
                } else {
                    openAlert(erro, false)
                }
            });
        }
    }

    const editScore = ({
        id,
        NomeScore,
        Valor
    }) => {
        api.put("EditarScore", {
            id,
            NomeScore,
            Valor
        }, res => {
            openAlert("Score atualizado com sucesso!");
            getscores();
        }, erro => {
            if (erro.response.status === 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    const filterNameScore = (e) => {

        if (e.target.value == '') {
            refPagination.current.Reload(oldScores);
            return;
        }

        const result = scores.filter(x => x.descricao.toUpperCase().includes(e.target.value.toUpperCase()));

        if (result.length === 0) {
            refPagination.current.Reload([]);
            setRecords([])
            return;
        }

        refPagination.current.Reload(result);

    }

    return (
        <>
            <Collapse in={openRegister}>
                <form className='container box_shadow_form' style={{ padding: '35px', width: '40%', borderRadius: '10px', marginTop: '2rem' }} onSubmit={e => { e.preventDefault(); registerScore() }}>
                    <br />
                    <center>
                        <h1>Cadastrar Novo Score</h1>
                        <small>Campos com * são obrigatórios</small>
                    </center>
                    <div className="row">
                        <div className="col-md-12">
                            <br />
                            <input value={name} onChange={e => setName(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome Score*' type="text" className='form-control' required />
                        </div>
                        <div className="col-md-12">
                            <br />
                            <input value={value} onChange={e => setValue(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10, margin: 5 }} placeholder='Valor Score*' type="number" min={1} max={99999} className='form-control' required />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <button type="button" style={{ width: '50%', backgroundColor: '#f2f2f2', color: '#000', padding: 15, borderRadius: 10 }} onClick={() => setOpenRegister(false)} className='btn'>FECHAR</button>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'end' }}>
                            <button type="submit" style={{ width: '50%' }} className='btn btn-primary'>CADASTRAR</button>
                        </div>
                    </div>
                </form>
            </Collapse>
            {openRegister && <hr />}
            {!openRegister && <br />}
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '500px', flexDirection: 'column' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4" />
                        <div className="col-md-4">
                            <h3 style={{ textAlign: 'center' }}>Scores cadastrados</h3>
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }}>
                            <button className='btn btn-success' onClick={() => setOpenRegister(!openRegister)}>Adicionar +</button>
                        </div>
                    </div>
                </div>
                {scores.length > 0 && <br />}
                {scores.length > 0 && <div style={{ display: 'flex' }}>
                    <input onChange={filterNameScore} style={{ width: '17rem', border: '1px solid dodgerblue', padding: 10 }} type="text" className='form-control' placeholder='Filtrar pelo nome do score' />
                </div>}
                {scores.length === 0 && <span>Nenhuma score cadastrado...</span>}
                {scores.length > 0 && <br />}
                {scores.length > 0 && <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Nome Score</th>
                            <th>Valor</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length === 0 && <td>Nenhum score encontrada...</td>}
                        {scores.length > 0 &&
                            records.map(item => {
                                const score = {
                                    id: item.id,
                                    NomeScore: item.descricao,
                                    Valor: item.pontos,
                                }
                                return <tr>
                                    <td>{score.NomeScore}</td>
                                    <td>{score.Valor}</td>
                                    <td style={{ width: 50 }}><button className='btn btn-success' onClick={() => refEdit.current.ToEdit(score)} >Editar</button></td>
                                    <td style={{ width: 50 }}><button className='btn btn-danger' onClick={() => deleteScore(score.id)}>Excluir</button></td>
                                </tr>
                            })}
                    </tbody>
                </table>}
                <Pagination
                    ref={refPagination}
                    data={scores}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
            <EditScore ref={refEdit} Edit={editScore} />
        </>
    )
}
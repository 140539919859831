import React, { Component, createRef } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class EditScore extends Component {

    constructor() {
        super()
        this.state = {
            show: false,
            id: '',
            NomeScore: '',
            Valor: '',
        }
        this.ToEdit = ({ id, NomeScore, Valor }) =>
            this.setState({ id, NomeScore, Valor, show: true })
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    render() {
        const {
            id,
            NomeScore,
            Valor
        } = this.state;

        const { Edit } = this.props;

        return (<>

            <Modal show={this.state.show}>
                <form onSubmit={e => {
                    e.preventDefault(); Edit({
                        id,
                        NomeScore,
                        Valor
                    },); this.setState({ show: false })
                }}>
                    <Modal.Header>
                        <h3>Editar Score</h3>
                        <Button onClick={() => { this.handleModal() }}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <input value={NomeScore} onChange={e => this.setState({ NomeScore: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome Score*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12">
                                <br />
                                <input value={Valor} onChange={e => this.setState({ Valor: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10, margin: 5 }} placeholder='Valor Score*' type="number" min={1} max={99999} className='form-control' required />
                            </div>
                        </div>
                        <button className="btn btn-success" type="submit">Editar</button>
                    </Modal.Body>
                </form>
            </Modal>
        </>)
    }
}
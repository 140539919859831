import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Layout from '../components/Layout/layout'
import Private from './privateRoute';
import Login from '../Views/Login';
import Home from '../Views/Home';
import Search from '../Views/Search';
import NotFound from '../Views/NotFound'
import ImportHistory from '../Views/ImportHistory';
import ImportEnrichment from '../Views/TelophinyEnrichment';
import SearchAllCpf from '../Views/SearchAllCpf';
import RegisterCompany from '../Views/RegisterCompany';
import RegisterOperador from '../Views/RegisterOperador';
// import Test from '../Views/Test';
import RecoverPsw from '../Views/RecoverPsw/recoverpsw';
import RegisterScore from '../Views/RegisterScore';
import DashTelefonia from '../Views/DashTelefonia';
import DashHistorico from '../Views/DashHistorico';
import AddNewLayout from '../Views/AddLayouyt';
import AddNewLayoutBase from '../Views/AddLayoutBase';
import ToManagerLayout from '../Views/ToManagerLayout';
import ImportMailing from '../Views/ImportMailing';
import ImportBase from '../Views/ImportBase';
import Approval from '../Views/Approval';
import Score from '../Views/Score/score';
import Ocorrencia from '../Views/Ocorrencia/ocorrencia';
import Painel from '../Views/Painel';
import Usuarios from '../Views/Usuarios/index';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Private path="/painelcontrole" exact>
                    <Layout Tela={'Painel De Controle'}>
                        <Painel />
                    </Layout>
                </Private>
                <Private path="/ocorrencia" exact>
                    <Layout Tela={'Ocorrência'}>
                        <Ocorrencia />
                    </Layout>
                </Private>
                <Private path="/category" exact>
                    <Layout Tela={'Scores'}>
                        <Score />
                    </Layout>
                </Private>
                <Private path="/approval" exact>
                    <Layout Tela={'Aprovação Quarentena'}>
                        <Approval />
                    </Layout>
                </Private>
                <Private path="/dashhistorico" exact>
                    <DashHistorico />
                </Private>
                <Private path="/importbase" exact>
                    <Layout Tela={'Importar Base'}>
                        <ImportBase />
                    </Layout>
                </Private>
                <Private path="/home" exact>
                    <Layout Tela={'Home'}>
                        <Home />
                    </Layout>
                </Private>
                <Private path="/users" exact>
                    <Layout Tela={'Usuários'}>
                        <Usuarios />
                    </Layout>
                </Private>
                <Private path="/addlayout" exact>
                    <Layout Tela={'Adicionar Novo Layout'}>
                        <AddNewLayout />
                    </Layout>
                </Private>
                <Private path="/addlayoutBase" exact>
                    <Layout Tela={'Adicionar Layout'}>
                        <AddNewLayoutBase />
                    </Layout>
                </Private>
                <Private path="/tomanagerlayout" exact>
                    <Layout Tela={'Configuração De Layout'}>
                        <ToManagerLayout />
                    </Layout>
                </Private>
                <Private path="/score" exact>
                    <Layout Tela={'Registrar Novo Score'}>
                        <RegisterScore />
                    </Layout>
                </Private>
                <Private path="/history" exact>
                    <Layout Tela={'Importar Telefonia'}>
                        <ImportHistory />
                    </Layout>
                </Private>
                {/* <Private path="/enrichment" exact>
                    <Layout>
                        <ImportEnrichment />
                    </Layout>
                </Private> */}
                <Private path="/search" exact>
                    <Layout Tela={'Filtrar Único CPF'}>
                        <Search />
                    </Layout>
                </Private>
                <Private path="/allcpf" exact>
                    <Layout Tela={'Enriquecimento Mailing'}>
                        <SearchAllCpf />
                    </Layout>
                </Private>
                <Private path="/registerCompany" exact>
                    <Layout>
                        <RegisterCompany />
                    </Layout>
                </Private>
                <Private path="/registerOperdor" exact>
                    <Layout>
                        <RegisterOperador />
                    </Layout>
                </Private>

                {/*public routes*/}
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route path="/recoverpsw">
                    <RecoverPsw />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Router >
    );
}
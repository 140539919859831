import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../Util/AlertUtil';
import { FaPencil } from 'react-icons/fa6';
import { api } from '../Api/api';

export default ({ id, name, tipo_Origem }) => {

    const [show, setShow] = useState(false);
    const [newName, setNewName] = useState('');
    const [tipoArquivo, setTipoArquivo] = useState('');
    const [layout, setLayout] = useState([]);

    useEffect(() => {
        setNewName(name);
        setTipoArquivo(tipo_Origem);
        fetchLayout();
    }, [])

    const handleSend = () => {
        const data = {
            id: id,
            NovoApelido: newName,
            tipo_Origem: tipoArquivo
        };
        api.put("File/UpdateLayouts", data, res => {
            window.location.reload();
        }, error => {
            openAlert('Algo deu errado\n erro:' + error.response.data, false)
        });
    }

    const fetchLayout = async () => {
        try {
            const response = await api.getAsync(`File/BuscarLayout?layoutId=${id}`);
            setLayout(response.data); // Define o estado com os dados da Promise resolvida
        } catch (error) {
            openAlert('Algo deu errado\n erro:' + error, false);
            console.error('Erro ao buscar os detalhes do layout:', error);
            setLayout([]); // Define o estado como um array vazio em caso de erro
        }
    };

    const handleTipoChange = (event) => {
        setTipoArquivo(event.target.value);
    };

    return (
        <>
            <button onClick={() => setShow(true)} className='btn btn-primary' type="button"><FaPencil color='#fff' /></button>

            <Modal show={show}>
                <Modal.Header>
                    <span>Editar Layout "{name}"</span>
                </Modal.Header>

                <Modal.Body>
                    <span>Escreva o novo apelido</span>
                    <input value={newName} onChange={e => setNewName(e.target.value.toUpperCase())} type="text" name="name" id="name" />
                </Modal.Body>

                <Modal.Body>
                    <span>Selecione o tipo de layout</span>
                    <div className="row">
                        <br />
                        <div className="col-md-12">
                            <form>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="tipoArquivo"
                                        id="crm"
                                        value="CRM"
                                        checked={tipoArquivo === 'CRM'}
                                        onChange={handleTipoChange}
                                    />
                                    <label className="form-check-label" htmlFor="crm">
                                        CRM
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="tipoArquivo"
                                        id="pabx"
                                        value="PABX"
                                        checked={tipoArquivo === 'PABX'}
                                        onChange={handleTipoChange}
                                    />
                                    <label className="form-check-label" htmlFor="pabx">
                                        PABX
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Body>
                    <div>
                        <span className="d-block text-center font-weight-bold mb-3">Abaixo campos do layout.</span>
                        <table className='table table-striped'>
                            <thead>
                                <th>Refer&ecirc;ncia</th>
                                <th>Coluna Layout</th>
                                <th>Posi&ccedil;&atilde;o</th>
                            </thead>
                            <tbody>
                                {layout.map((item, index) => {
                                    return <tr>
                                        <th>{item.nome}</th>
                                        <td>{item.descricao}</td>
                                        <td>{item.posicao}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <span>Os dados do layout são exibidos apenas para consulta. Para realizar ajustes, por favor, exclua e cadastre novamente</span>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => setShow(false)}>Fechar</Button>
                    <Button className="btn btn-primary" type='button' onClick={handleSend}>Enviar</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

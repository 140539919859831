import React from 'react';
import { useState } from 'react';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';

function RecoverPsw() {
    const [psw, setPsw] = useState('');
    const [confirmPsw, setConfirmPsw] = useState('');
    const [showPsw, setshowPsw] = useState(false);

    var params = new URLSearchParams(document.location.search)
    var token = params.get("token");

    if (!token) {
        window.location.href = "/";
    }

    const submit = () => {
        if (psw != confirmPsw) {
            openAlert('Senha não se coincidem!', false, true);
            return
        }

        const data = new FormData()
        data.append("senha", psw);
        data.append("token", token);
        api.post("/Usuario/AlterarSenha", data, res => {
            openAlert('Senha alterada com sucesso!');
        }, erro => {
            if (erro.response.status == 400) {
                openAlert(erro.response.data, false)
            } else {
                openAlert(erro, false)
            }
        });
    }

    return (
        <form className='container box_shadow_form' style={{ padding: '35px', width: '40%', borderRadius: '10px', marginTop: '2rem' }} onSubmit={e => { e.preventDefault(); submit() }}>
            <br />
            <center>
                <h1>Alterar Senha</h1>
            </center>
            <div className="row">
                <div className="col-md-12">
                    <br />
                    <input value={psw} onChange={e => setPsw(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Digite sua nova senha' type={showPsw ? 'text' : 'password'} className='form-control' required />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <br />
                    <input value={confirmPsw} onChange={e => setConfirmPsw(e.target.value)} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Confirma sua nova senha' type={showPsw ? 'text' : 'password'} className='form-control' required />
                </div>
            </div>
            <br />
            <div>
                <input type="checkbox" onChange={() => setshowPsw(!showPsw)} />
                <small>Visualizar senhas</small>
            </div>
            <br />
            <div className="row">
                <div className="col-md-12">
                    <button type="submit" style={{ width: '100%' }} className='btn btn-primary'>ALTERAR</button>
                </div>
            </div>
        </form>
    );
}

export default RecoverPsw;
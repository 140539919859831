import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../Util/AlertUtil';
import { FaRegEye, FaCheck } from 'react-icons/fa';
import { api } from '../../components/Api/api';

import Collapse from 'react-bootstrap/Collapse';
import MapDetails from './mapDetails';

export default ({ id, onReload }) => {
    const defaultValue = {
        Bancarios: {
            "id": 0,
            "cpf": "",
            "tipoConta": "",
            "banco": "",
            "agencia": "",
            "conta": "",
            "salario": ""
        },
        Beneficios: {
            "id": 0,
            "cpf": "",
            "tipoBeneficio": "",
            "beneficioPrincipal": ""
        },
        Pessoal: {
            "id": 0,
            "nome": "",
            "nome_Mae": "",
            "nome_Pai": "",
            "rg": "",
            "emissor_Rg": "",
            "data_Emissao_Rg": "",
            "orgao_emissor": "",
            "origem_cliente": "",
            "data_Atualizacao": "",
            "email": ""
        },
        Quarentena: {
            "id": 0,
            "cpf": "",
            "ddd": "",
            "telefone": "",
            "origem": "",
            "empresa_Origem": "",
            "data_discagem": "",
            "numero_testes": "",
            "ocorrencia": "",
            "ocorrencia_final": "",
            "historico_excel_id": ""
        }
    };

    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [showQuarentena, setShowQuarentena] = useState(false);
    const [showPessoais, setShowPessoais] = useState(false);
    const [showBancarios, setShowBancarios] = useState(false);
    const [showBeneficio, setShowBeneficio] = useState(false);

    const [formBeneficio, setFormBeneficio] = useState({});
    const [formBancarios, setFormBancarios] = useState({});
    const [formPessoais, setFormPessoais] = useState({});
    const [formQuarentena, setFormQuarentena] = useState({});

    const getDetails = () => {
        api.get(`Quarentena/GetDetalhesQuarentena/${id}`, res => {
            console.clear();
            console.log(res.data);
            setFormBeneficio(res.data.dadosBeneficio ? res.data.dadosBeneficio : defaultValue.Beneficios);
            setFormBancarios(res.data.dadosBancarios ? res.data.dadosBancarios : defaultValue.Bancarios);
            setFormPessoais(res.data.dadosPessoais ? res.data.dadosPessoais : defaultValue.Pessoal);
            setFormQuarentena(res.data.histQuarentena ? res.data.histQuarentena : defaultValue.Quarentena);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    const openModal = () => {
        getDetails();
        setShow(true);
    }

    const onChangeBeneficio = e =>
        setFormBeneficio({ ...formBeneficio, [e.target.name]: e.target.value });

    const onChangeBancarios = e =>
        setFormBancarios({ ...formBancarios, [e.target.name]: e.target.value });

    const onChangePessoais = e =>
        setFormPessoais({ ...formPessoais, [e.target.name]: e.target.value });

    const onChangeQuarentena = e =>
        setFormQuarentena({ ...formQuarentena, [e.target.name]: e.target.value });

    const onSaveChanges = (toApprove = false) => {
        if (window.confirm("Deseja realmente salvar as informações?")) {

            const data = new FormData();
            data.append("DadosBeneficio", JSON.stringify(formBeneficio));
            data.append("DadosBancarios", JSON.stringify(formBancarios));
            data.append("DadosPessoais", JSON.stringify(formPessoais));
            data.append("HistQuarentena", JSON.stringify(formQuarentena));
            data.append("Aprovar", toApprove);

            api.post(`Quarentena/AtualizarDetalhes`, data, res => {
                openAlert('Atualização realizada com sucesso');
                onReload();
                setShow(false);
                setIsEdit(false);
            }, erro => {
                openAlert('Algo deu errado\n erro:' + erro, false)
            })
        }
    }

    return (
        <>
            <a className='btn btn-link' onClick={openModal}><FaRegEye color='#000' /></a>

            <Modal show={show}>
                <Modal.Header>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Detalhes:</span>
                        <div>
                            <label><input checked={isEdit} onChange={e => setIsEdit(!isEdit)} type="checkbox" /></label>
                            <label>Habilitar Edição</label>
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <h5 style={{ textAlign: 'center' }}>CPF</h5>
                    <input disabled={!isEdit} className='form-control' style={{ padding: 10, border: '1px solid dodgerblue' }} type="text" name='cpf' onChange={onChangeQuarentena} value={formQuarentena.cpf} />
                    <hr />
                    <button style={{ width: '100%', marginBottom: '10px' }} onClick={() => setShowQuarentena(!showQuarentena)}>Ver Dados Quarentena</button>
                    <Collapse in={showQuarentena}>
                        <div>
                            <MapDetails
                                isEdit={isEdit}
                                onChange={onChangeQuarentena}
                                state={formQuarentena}
                            />
                        </div>
                    </Collapse>
                    <hr />
                    <button style={{ width: '100%', marginBottom: '10px' }} onClick={() => setShowPessoais(!showPessoais)}>Ver Dados Pessoais</button>
                    <Collapse in={showPessoais}>
                        <div>
                            <MapDetails
                                isEdit={isEdit}
                                onChange={onChangePessoais}
                                state={formPessoais}
                            />
                        </div>
                    </Collapse>
                    <hr />
                    <button style={{ width: '100%', marginBottom: '10px' }} onClick={() => setShowBancarios(!showBancarios)}>Ver Dados Bancarios</button>
                    <Collapse in={showBancarios}>
                        <div>
                            <MapDetails
                                isEdit={isEdit}
                                onChange={onChangeBancarios}
                                state={formBancarios}
                            />
                        </div>
                    </Collapse>
                    <hr />
                    <button style={{ width: '100%', marginBottom: '10px' }} onClick={() => setShowBeneficio(!showBeneficio)}>Ver Dados Beneficio</button>
                    <Collapse in={showBeneficio}>
                        <div>
                            <MapDetails
                                isEdit={isEdit}
                                onChange={onChangeBeneficio}
                                state={formBeneficio}
                            />
                        </div>
                    </Collapse>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={() => setShow(false)}>Voltar</Button>
                    {isEdit && <Button onClick={() => onSaveChanges(false)} className="btn btn-primary" type='button'>Salvar</Button>}
                    {isEdit && <Button onClick={() => onSaveChanges(true)} className="btn btn-success" type='button'>Salvar e Aprovar</Button>}
                </Modal.Footer>
            </Modal>

        </>
    );
};

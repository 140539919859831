import React, { Component, createRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { openAlert } from '../../../Util/AlertUtil'
import { api } from '../../Api/api'

export default class ModalOperador extends Component {

    constructor() {
        super()
        this.state = {
            show: false,
            idOperador: 0,
            operadorName: '',
            email: '',
            cpf: '',
            phone: '',
            isEdit: false,
            empresaId: 0
        }
        this.ToEdit = (id) => {
            api.get('Usuario/Operador/' + id, res => {
                this.setState({
                    show: true,
                    idOperador: res.data.idOperador,
                    operadorName: res.data.nomeOperador,
                    email: res.data.emailOperador,
                    cpf: res.data.cpfOperador.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
                    phone: res.data.telefoneOperador.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
                    isEdit: true
                });
            }, err => openAlert(err.response.data, false));
        }
        this.Novo = (empresaId) => this.setState({ show: true, isEdit: false, empresaId: empresaId });
    }

    initialState = {
        show: false,
        idOperador: 0,
        operadorName: '',
        email: '',
        cpf: '',
        phone: '',
        isEdit: false,
        empresaId: 0
    };

    handleModal() {
        this.setState(this.initialState)
    }

    render() {
        const {
            idOperador,
            operadorName,
            email,
            cpf,
            phone,
            isEdit,
            empresaId
        } = this.state;

        const maskPhone = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ phone: e.target.value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") })
            }
        }

        const maskCpf = (e) => {
            if (isNum(e.target.value)) {
                this.setState({ cpf: e.target.value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") })
            }
        }

        const isNum = (value) => {
            value = value.replaceAll('.', '').replaceAll('-', '')
                .replaceAll('/', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
            var er = /[^0-9.]/;
            er.lastIndex = 0;
            if (er.test(value)) {
                return false;
            }
            return true;
        }

        const handleSubmit = () => {
            if (isEdit) {
                api.put("Usuario/AtualizarOperador", {
                    IdOperador: idOperador,
                    NomeOperador: operadorName,
                    EmailOperador: email,
                    CpfOperador: cpf,
                    TelefoneOperador: phone
                }, res => {
                    openAlert("Operador atualizado com sucesso!")
                    this.props.AtualizarTela()
                    this.setState(this.initialState);
                }, erro => {
                    if (erro.response.status === 400) {
                        openAlert(erro.response.data, false)
                    } else {
                        openAlert(erro, false)
                    }
                });
            } else {
                api.post("/Usuario/CadastroOperador/" + empresaId, {
                    Email: email,
                    Cpf: cpf,
                    NomeOperador: operadorName,
                    Telefone: phone,
                }, res => {
                    openAlert('Cadastro Realizado com sucesso!\nFoi enviado as credenciais de acesso no email: ' + email);
                    this.props.AtualizarTela()
                    this.setState(this.initialState);
                }, erro => {
                    if (erro.response.status === 400) {
                        openAlert(erro.response.data, false)
                    } else {
                        openAlert(erro, false)
                    }
                });
            }
        }

        return (<>

            <Modal show={this.state.show}>
                <form onSubmit={e => {
                    e.preventDefault(); handleSubmit(); this.setState({ show: false })
                }}>
                    <Modal.Header>
                        <h3>{isEdit ? 'Editar' : 'Cadastrar'} Operador</h3>
                        <Button onClick={() => { this.handleModal() }}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <small>Campos com * são obrigatórios</small>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 10 }}>
                                <label>Nome do Operador*</label>
                                <input value={operadorName} onChange={e => this.setState({ operadorName: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Nome do operador*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <label>Email do operador*</label>
                                <input value={email} onChange={e => this.setState({ email: e.target.value })} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='Email do operador*' type="email" className='form-control' required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: 20 }}>
                                <label>CPF do Operador*</label>
                                <input value={cpf} onChange={maskCpf} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='CPF*' type="text" className='form-control' required />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 20 }}>

                                <label>DDD+Telefone*</label>
                                <input value={phone} onChange={maskPhone} minLength={14} maxLength={14} style={{ border: '1px solid dodgerblue', padding: 10 }} placeholder='DDD* + Telefone*' type="text" className='form-control' required />
                            </div>
                        </div>
                        <button className="btn btn-success" type="submit">{isEdit ? 'Editar' : 'Cadastrar'}</button>
                    </Modal.Body>
                </form>
            </Modal>
        </>)
    }
}
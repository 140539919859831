import React from 'react';
import './history.css';
import { MdDownloadForOffline } from 'react-icons/md';
import { AiFillFile } from 'react-icons/ai';
import { DownloadFile } from '../../Util/FileUtil';
import { GetDateNow, CompareDate } from '../../Util/DateUtil';
import { closeAlert, openAlert } from '../../Util/AlertUtil';
import { api } from '../../components/Api/api';

class ImportHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            phoneHistory: []
        }
    }

    componentDidMount() {
        api.get("GetPhoneHistory", res => {
            const result = [
                { id: 1, fileName: 'telefonia.csv', sendDate: '20/10/2023', status: 'Pendente' },
                { id: 2, fileName: 'historico_telefonia.csv', sendDate: '20/10/2023', status: 'Não processado' },
                { id: 3, fileName: 'historico_telefonia.csv', sendDate: '20/10/2023', status: 'Concluído' },
                { id: 4, fileName: 'fonia.csv', sendDate: '20/10/2023', status: 'Concluído' },
                { id: 5, fileName: 'historico_telefonia.csv', sendDate: '20/10/2023', status: 'Concluído' },
                { id: 6, fileName: 'historico_telefonia.csv', sendDate: '20/10/2023', status: 'Erro' },
                { id: 7, fileName: 'telefonia.csv', sendDate: '20/10/2023', status: 'Concluído' },
                { id: 8, fileName: 'historico.csv', sendDate: '20/10/2023', status: 'Concluído' },
                { id: 9, fileName: 'hist_fonia.csv', sendDate: '20/10/2023', status: 'Concluído' },
                { id: 10, fileName: 'historico_telefonia.csv', sendDate: '20/10/2023', status: 'Concluído' },
            ];
            this.setState({ phoneHistory: result }); //res.data
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })

        //temp
        const result = [
            { id: 1, fileName: 'telefonia.csv', sendDate: '11/10/2023', status: 'Pendente' },
            { id: 2, fileName: 'historico_telefonia.csv', sendDate: '15/10/2023', status: 'Não processado' },
            { id: 3, fileName: 'historico_telefonia.csv', sendDate: '17/10/2023', status: 'Concluído' },
        ];
        this.setState({ phoneHistory: result.reverse() })

    }

    render() {
        const { phoneHistory } = this.state;

        const validateForm = () => this.state.selectedFile != null;

        const haddleSubmit = () => {
            if (validateForm()) {
                const { selectedFile } = this.state;

                const history = {};
                history.historyId = 0;
                history.fileName = selectedFile.name;
                history.sendDate = GetDateNow();
                history.status = 1; // 1 = 'Processando' 2 = 'Importado' 3 = 'Erro'
                history.type = 1;

                // api.post("SendHistory", history, res => {
                //     history.historyId = res.data;
                // }, erro => {
                //     openAlert('Algo deu errado\n erro:' + erro)
                // })

                var current_history = this.state.phoneHistory.reverse();
                current_history.push(history);
                this.setState({ phoneHistory: current_history.reverse() })

                api.uploadFileForm("File/UploadEnriquecimentoTelofina", res => {
                    openAlert(res.data);
                }, erro => {
                    if (Array.isArray(erro.response.data)) {
                        let erros = "";
                        erro.response.data.forEach(element => {
                            erros += "\n" + element.descricao + "\n";
                        });
                        openAlert('Algo deu errado\n erro:' + erros, false)
                    }
                    else {
                        openAlert('Algo deu errado\n erro:' + erro.response.data, false)
                    }
                });

            } else {
                openAlert('Selecione um arquivo', false, true)
            }
        }

        const handleFileChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                const fileName = file.name;
                if (!fileName.endsWith('.csv')) {
                    openAlert('Selecione um arquivo CSV.', false, true);
                    return;
                }
                const reader = new FileReader();

                reader.onload = (event) => {
                    const fileContent = event.target.result;
                    const lines = fileContent.split('\n');

                    const header = lines[0].split(';');

                    const expectedHeader = ['Data Discagem', 'Cpf', 'Telefone', 'Status'];

                    // if (!arraysEqual(header, expectedHeader)) {
                    //     openAlert('Cabeçalho inválido. Os nomes de coluna esperados são:\n' + `${expectedHeader[0]}, ${expectedHeader[1]}, ${expectedHeader[2]}, ${expectedHeader[3]}`);
                    //     this.setState({ selectedFile: null });
                    // }
                };
                reader.readAsText(file);
                this.setState({ selectedFile: file })
            }
        };

        function arraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;
        }

        return (
            <section className='container_history'>
                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                    <div style={{ width: '150px', height: '50px' }}></div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: 'column' }}>
                        <div>
                            <h1 style={{ textAlign: 'center' }}>Enriquecimento <span style={{ textDecoration: 'underline dodgerblue', color: 'dodgerblue' }}> Telofina</span>
                            </h1>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '100%' }}>
                            <input onChange={handleFileChange} style={{ outline: '1px solid #008552', padding: '10px 20px' }} type='file' name='file' id='file' />
                            <button onClick={haddleSubmit} className='btn-download'
                                style={{
                                    padding: '13px',
                                    borderRadius: '0px 10px 10px 0px'
                                }}
                            >Enivar</button>
                        </div>
                    </div>
                    <button type='button' onClick={() => DownloadFile('/Templates/template_historico_telefonia.csv')} style={{ padding: 10, marginRight: 50 }} className='btn-download'><AiFillFile />Template</button>
                </div>
                <br />
                <hr />
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Nome do arquivo</th>
                            <th>Data de envio</th>
                            <th>status</th>
                            <th style={{ width: 20 }}>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            phoneHistory.length == 0 ? <tr key={1}>Não há historico</tr>
                                :
                                phoneHistory.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.fileName}</td>
                                            <td>{item.sendDate}</td>
                                            <td>{item.status == 1 ? "Processando" : item.status == 2 ? "Importado" : "Erro"}</td>
                                            <td><button className='btn-download'
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    padding: '5px 5px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignContent: 'center'
                                                }}
                                            ><MdDownloadForOffline size={25} /></button></td>
                                        </tr>
                                    )
                                })
                        }
                    </tbody>
                </table>
            </section>
        );
    }
}

export default ImportHistory;
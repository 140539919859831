import React, { useState } from 'react';
import '../AddLayouyt/addLayouyt.css';
import Layout from '../../components/Layout/layout';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';


function ImportMailing() {
    const Enum = {
        Operadora: 0,
        Origem: 1,
        CPF: 2,
        Data_Atualizacao: 3,
        Score: 4,
        Tel: 5,
        DDD: 6,
        Facebook: 7,
        Whatsapp: 8
    };

    const [layout, setLayout] = useState([]);
    const [map, setMap] = useState({
        Operadora: -1,
        Origem: -1,
        CPF: -1,
        Data_Atualizacao: -1,
        Score: -1,
        Tel: -1,
        DDD: -1,
        Facebook: -1,
        Whatsapp: -1
    });

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            if (!fileName.endsWith('.csv')) {
                openAlert('Selecione um arquivo CSV.', false, true);
                return;
            }

            // Simulando leitura de cabeçalhos CSV
            const reader = new FileReader();
            reader.onload = async (event) => {
                const content = event.target.result;
                const headers = content.split('\n')[0].split(';');
                setLayout(headers);

                // Resetando mapeamento para os cabeçalhos encontrados
                const newMap = { ...map };
                Object.keys(newMap).forEach(key => newMap[key] = -1);
                headers.forEach((header, index) => {
                    Object.keys(Enum).forEach(enumKey => {
                        if (header === enumKey) newMap[enumKey] = index;
                    });
                });
                setMap(newMap);
            };
            reader.readAsText(file);
        }
    };



    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    };

    const changeDisabled = (disabled, id) => {
        var options = document.querySelectorAll("#value-" + id);
        options.forEach(element => {
            element.disabled = disabled;
        });
    }


    const formIsValid = () => {
        if (map.CPF === -1) {
            alert("CPF é um campo obrigatório.")
            return false;
        }
        return true;
    }

    const DownloadFile = (file) => {
        let csvContent = atob(file);
        var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url
        a.download = "log.csv";
        a.click();
    }

    const sendForm = () => {
        if (formIsValid()) {
            const data = new FormData();
            var input = document.querySelector('input[type="file"]');
            for (const file of input.files) {
                data.append('file', file, file.name)
            }

            data.append("Operadora", map.Operadora);
            data.append("Origem", map.Origem);
            data.append("CPF", map.CPF);
            data.append("Data_Atualizacao", map.Data_Atualizacao);
            data.append("Score", map.Score);
            data.append("Tel", map.Tel);
            data.append("DDD", map.DDD);
            data.append("Facebook", map.Facebook);
            data.append("Whatsapp", map.Whatsapp);

            api.post("File/UploadTelefonia", data, res => {
                openAlert(res.data.mensagem);
                if (res.data.arquivoB64) {
                    DownloadFile(res.data.arquivoB64);
                }
            }, erro => {
                openAlert("Houve um erro ao importar o mailing: " + erro.response.data, false);
            })
        }
    }


    return (
        <Layout>
            <div>
                <button className='btn btn-primary' style={{ marginLeft: 30 }} onClick={() => window.history.back()}>Voltar</button>
                <br />
                <br />
                <div className="container">
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <spam style={{ texta: 30 }}>1°- Importe seu arquivo <b>(Arquivo tem que ser em.CSV)</b></spam>
                            <input onChange={handleFileChange} type="file" className='form-control' name="file" id="file" />
                        </div>
                    </div>
                </div>
                <br />
                {layout.length > 0 && <div className="container"><spam >2°- Realiza o mapeamento do Layout importado <b>(seu x nosso)</b></spam></div>}
                {layout.length > 0 && <div className="container"><small >obs:</small></div>}
                {layout.length > 0 && <div className="container"><small >CPF é campos obrigatório.</small></div>}
                {layout.length > 0 && <div className="container"><small >Caso DDD estaja junto com o Telefone, selecione apenas o campo "Telefone".</small></div>}
                {layout.length > 0 && <div className="container"><small >Caso o campo não exista em seu arquivo, selecione "N/A".</small></div>}
                <br />
                {/* <input type="file" onChange={handleFileChange} />*/}
                {layout.length > 0 && (
                    <div>
                        <div className="container">
                            <table className='table table-striped'>
                                <thead>
                                    <tr>{layout.map((header, index) => <th key={index}>{header}</th>)}</tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {Object.keys(Enum).map((key, idx) => (
                                            <td key={idx}>
                                                <select
                                                    value={map[key]}
                                                    onChange={(e) => setMap({ ...map, [key]: parseInt(e.target.value) })}
                                                >
                                                    <option value={-1}>N/A</option>
                                                    {layout.map((item, index) => (
                                                        <option key={index} value={index}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            {/*<button onClick={sendForm}>Enviar Mapeamento</button>*/}
                            <spam >3°- Envia o mapeamento do <b>Mailing Mapeado</b></spam>
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' onClick={sendForm} className='btn btn-primary'>Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default ImportMailing;
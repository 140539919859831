import CryptoJS from 'crypto-js';

const encryptionKey = 'owiurqoiurioqwoeifgqoweifgob3r2jwef';

export function Encrypt(text) {
  const encrypted = CryptoJS.AES.encrypt(text, encryptionKey).toString();
  return encrypted;
}

export function Decrypt(encryptedText) {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, encryptionKey).toString(CryptoJS.enc.Utf8);
  return decrypted;
}
import React from 'react';
import './index.css'

export default _ => {
    return (
        <section class="page_404">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 ">
                        <div class="col-sm-10 col-sm-offset-1  text-center">
                            <div class="four_zero_four_bg">
                                <h1 class="text-center" style={{textAlign:'center '}}>404</h1>
                            </div>
                            <div class="contant_box_404">
                                <h3 class="h2">
                                    Parece que você está perdido
                                </h3>

                                <p>a página que você procura não está disponível!</p>

                                <a href="home" onClick={()=> window.history.back()} class="link_404">Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
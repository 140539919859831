import React, { useRef, useState } from 'react';
import { Table, Button, Collapse } from 'react-bootstrap';
import EditCompany from '../../components/Modals/EditCompany';
import { FaPlus } from 'react-icons/fa';
import { FaHelmetSafety, FaPencil } from "react-icons/fa6";

const EmpresasTable = ({ data, inactivateCompany, activateCompany, usuarioId, refModalEmpresa, refModalOperador, activateOperador, inactivateOperador }) => {
    const [open, setOpen] = useState({});

    const toggleCollapse = (index) => {
        setOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const getTipoUsuario = (id) => {
        if (id === 1) return "MASTER";
        if (id === 2) return "EMPRESA";
        if (id === 3) return "OPERADOR";
        if (id === 4) return "PARCEIRO";
    }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Nome da Empresa</th>
                    <th>Email</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Quantidade De Operadores</th>
                    <th>Ver Operadores</th>
                    <th>Editar Dados Empresa</th>
                    <th>Mudar Status</th>
                    <th>Cadastrar Novo Operador</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>{item.empresa.usuario_nome}</td>
                            <td>{item.empresa.usuario_email}</td>
                            <td>{getTipoUsuario(item.empresa.usuario_tipo)}</td>
                            <td>{item.empresa.usuario_status ? 'Ativo' : 'Inativo'}</td>
                            <td>{item.operadores.length}</td>
                            <td>
                                <Button
                                    style={{ marginTop: 10, width: '100%' }}
                                    variant="warning"
                                    onClick={() => toggleCollapse(index)}
                                    aria-controls={`collapse-${index}`}
                                    aria-expanded={open[index]}
                                >
                                    <FaHelmetSafety color='#fff' size={25} />
                                </Button>
                            </td>
                            <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {item.empresa.usuario_status && <td><button
                                    style={{ width: '100%' }}
                                    className='btn btn-success'
                                    onClick={() => refModalEmpresa.current.ToEdit(item.empresa.usuario_id)} >
                                    <FaPencil size={25} />
                                </button></td>}
                                {!item.empresa.usuario_status && '-'}
                            </td>
                            {usuarioId !== item.empresa.usuario_id && <td>
                                {item.empresa.usuario_status && <td><button className='btn btn-danger' onClick={() => inactivateCompany(item.empresa.usuario_id)}>Inativar</button></td>}
                                {!item.empresa.usuario_status && <td><button className='btn btn-warning' onClick={() => activateCompany(item.empresa.usuario_id)}>Ativar</button></td>}
                            </td>}
                            {usuarioId === item.empresa.usuario_id && <td>-</td>}
                            <td style={{ marginTop: 10 }}>
                                <button className='btn btn-primary' onClick={() => refModalOperador.current.Novo(item.empresa.usuario_id)}>
                                    <FaPlus />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="11">
                                <Collapse in={open[index]}>
                                    <div id={`collapse-${index}`} style={{ paddingLeft: '20rem' }}>
                                        <Table size="sm" striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>Nome do Operador</th>
                                                    <th>Email</th>
                                                    <th>Tipo</th>
                                                    <th>Status</th>
                                                    <th>Editar Dados Operador</th>
                                                    <th>Mudar Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.operadores.length > 0 ? (
                                                    item.operadores.map((operador, opIndex) => (
                                                        <tr key={opIndex}>
                                                            <td>{operador.usuario_nome}</td>
                                                            <td>{operador.usuario_email}</td>
                                                            <td>{getTipoUsuario(operador.usuario_tipo)}</td>
                                                            <td>{operador.usuario_status ? 'Ativo' : 'Inativo'}</td>
                                                            <td colSpan={'1'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                {operador.usuario_status && <td><button className='btn btn-success' onClick={() => refModalOperador.current.ToEdit(operador.usuario_id)} >Editar</button></td>}
                                                            </td>
                                                            <td>
                                                                {operador.usuario_status && <td><button className='btn btn-danger' onClick={() => inactivateOperador(operador.usuario_id)}>Inativar</button></td>}
                                                                {!operador.usuario_status && <td><button className='btn btn-warning' onClick={() => activateOperador(operador.usuario_id)}>Ativar</button></td>}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4">Nenhum operador cadastrado</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Collapse>
                            </td>
                        </tr>
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    );
};

export default EmpresasTable;

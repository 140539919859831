import React, { useState, useRef, useEffect } from 'react';
import ScoreTable from './scoreTable';
import Pagination from '../../components/Pagination/pagination';
import { FaPlus } from 'react-icons/fa';
import NewScore from './newScore';
import { api } from '../../components/Api/api';
import { openAlert } from '../../Util/AlertUtil';

function Score() {
    const [scores, setScores] = useState([]);
    const [records, setRecords] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const pagination = useRef();

    const BuscarScores = () => {
        api.get("BuscarTodosScore", res => {
            setScores(res.data);
            pagination.current.Reload(res.data);
        }, erro => {
            openAlert("Houve um erro na solicitação: " + erro.data, false)
        })
    }

    useEffect(() => {
        BuscarScores();
    }, []);

    const onChangeFilter = (e) => {
        const value = e.target.value;
        let filter = [];
        if (value === '') {
            filter = scores;
        } else {
            filter = scores.filter(score => score.descricao.toUpperCase().includes(value.toUpperCase()));
        }
        pagination.current.Reload(filter, true);
    }

    const onDeleteScore = (id) => {
        if (window.confirm("Deseja realmente deletar esse score?")) {
            api.delete("ExcluirScore?id=" + id, res => {
                openAlert("Score excluido com sucesso!");
                BuscarScores();
            }, erro => {
                openAlert("Houve um erro na solicitação: " + erro.data, false)
            })
        }
    }

    return (
        <div className='container'>
            <center>
                <h1>Score</h1>
            </center>
            <div className="row" >
                <div className="col-md-2" />
                <div className="col-md-8">
                    <label>Pesquisar:</label>
                    <input style={{ padding: 5, width: '100%', border: '1px solid dodgerblue' }} onChange={onChangeFilter} type="text" className='form-control' placeholder='Nome Do Score' />
                </div>
                <div className="col-md-1">
                    <NewScore
                        BuscarScores={BuscarScores}
                        show={showModalAdd}
                        setShow={setShowModalAdd}
                        Edit={false}
                        score={null}
                    />
                </div>
            </div>
            <div className='container' style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <br />
                <ScoreTable
                    ref={pagination}
                    scores={scores}
                    records={records}
                    setRecords={setRecords}
                    BuscarScores={BuscarScores}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onDeleteScore={onDeleteScore}
                />
                {scores.length === 0 && <span>Nenhum Score Cadastrado...</span>}
                <Pagination
                    ref={pagination}
                    data={scores}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
        </div>
    );
}

export default Score;
import React from 'react';
import { FaPencilAlt, FaTrash } from "react-icons/fa";

export default ({
    setShowModal,
    records,
    onDeleteOcorrencia,
    Scores,
    OpenModalOcorrencia
}) => {
    return (
        <table className='table table-striped' style={{ width: '60% !important' }}>
            <thead>
                <tr>
                    <th>N° Sequencial</th>
                    <th>Id Da Ocorrencia</th>
                    <th>Nome</th>
                    <th>Pontos</th>
                    <th>Editar</th>
                    <th>Excluir</th>
                </tr>
            </thead>
            {records.map((ocorrencia, i) => (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{ocorrencia.ocorrencia_id}</td>
                    <td>{ocorrencia.ocorrencia_nome}</td>
                    <td>{ocorrencia.ocorrencia_pontos}</td>
                    <td>
                        <button style={{ color: '#fff', width: '100%' }} onClick={() => OpenModalOcorrencia(ocorrencia.ocorrencia_id)} className={'btn btn-warning'}>{<FaPencilAlt size={10} />}</button>
                    </td>
                    <td><button style={{ width: '100%' }} onClick={() => onDeleteOcorrencia(ocorrencia.ocorrencia_id)} className='btn btn-danger'><FaTrash size={10} /></button></td>
                </tr>
            ))}
        </table>

    )
}